/**
 * axios 封装
 * Author: Thompson
 */
import Axios from "axios";

// import { login } from "@/service/login";
/**
 * 创建 axios 实例
 * @type {AxiosInstance}
 */
const Service = Axios.create({
  timeout: 30000,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
    get: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
});

/**
 * request 拦截器
 */
Service.interceptors.request.use(
  (config) => {
    // console.log(config)
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

/**
 * respone 拦截器
 */
Service.interceptors.response.use(
  (response) => {
    return Promise.resolve(response.data);
  },
  async (error) => {
    // let {
    //   response: { status },
    // } = error;
    // if (status == 401) {
    //   await login();
    // }
    return Promise.reject(error);
  }
);

export default Service;
