// 传入 cdn 地址
export function convertUrlToBase64(url) {
  return new Promise((resolve) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;
    img.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      let ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      let ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
      let dataURL = canvas.toDataURL("image/" + ext);
      let base64 = {
        dataURL: dataURL,
        type: "image/" + ext,
        ext: ext,
      };
      resolve(base64.dataURL);
    };
  });
}
