export const product = [
  {
    categoryImg: require("@/assets/images/customize/type-case.png"),
    categoryClassName: "img-case",
    categoryText: "Case",
  },
  {
    categoryImg: require("@/assets/images/customize/type-clothes.png"),
    categoryClassName: "img-clothes",
    categoryText: "Clothes",
  },
  {
    categoryImg: require("@/assets/images/customize/type-houseware.png"),
    categoryClassName: "img-houseware",
    categoryText: "Houseware",
  },
  {
    categoryImg: require("@/assets/images/customize/type-accessory.png"),
    categoryClassName: "img-accessory",
    categoryText: "Accessory",
  },
  {
    categoryImg: require("@/assets/images/customize/type-painting.png"),
    categoryClassName: "img-print",
    categoryText: "Painting",
  },
  {
    categoryImg: require("@/assets/images/customize/type-others.png"),
    categoryClassName: "img-others",
    categoryText: "Others",
  },
];
