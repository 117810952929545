// import * as API from "@api/request";
// import { getLocalStorage, setLocalStorage } from "@u/storage";

import { wallet } from "@u/wallet";

export const connect_wallet = async () => {
  const accounts = await wallet.connectMetamask();
  // const wallet_address = await wallet.getAddress();
  const wallet_balance = await wallet.getUserBalence();
  const user_data = {
    address: accounts[0],
    balance: wallet_balance,
    hasAccounts: accounts.length > 0,
  };

  // 如果用户token信息失效，需要重新登录
  // if (!getLocalStorage("Access_Token")) {
  //   const signInfo = await wallet.sign();
  //   const loginInfo = await API.excute("login", {
  //     message: `Login_at:${signInfo.message}`,
  //     wallet_address: accounts[0],
  //     signature: signInfo.signMessage,
  //   });
  //   setLocalStorage("Access_Token", loginInfo.session_token);
  // }
  return user_data;
};
