/**
 * 前端静态数据
 * Author: Thompson
 */
export const mobileType = [
  "iPhone XS Max",
  "iPhone 13 Mini",
  "iPhone 13 Pro Max",
  "iPhone 13 Pro ",
  "iPhone 12 Pro",
  "iPhone 11 Pro Max",
  "iPhone 11 Pro",
  "iPhone 11",
  "iPhone XR",
  "iPhone XS",
  "iPhone X",
  "iPhone 12 Mini",
  "iPhone 12 Pro Max",
  "iPhone 13",
  "iPhone 12",
];
