import { connect_wallet } from "@/service/getData";
// import { setStore } from "@/util"

const GET_USER_INFO = "GET_USER_INFO";
const SET_USER_INFO = "SET_USER_INFO";

const state = {
  userInfo: {}, // 用户信息
};

const mutations = {
  [GET_USER_INFO](state, info) {
    state.userInfo = info || {};
  },
  [SET_USER_INFO](state, data) {
    state.userInfo = data;
  },
};

const actions = {
  async getUserInfo({ commit }) {
    let res = await connect_wallet();
    console.log(res, "wallet");
    commit(GET_USER_INFO, res);
  },
  async setUserInfo({ commit }, params) {
    commit(SET_USER_INFO, params);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
