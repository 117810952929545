<template>
  <div id="contact">
    <div class="main-container contact-wrap">
      <p class="contact-title">CONTACT US</p>
      <div class="contact-content">
        <p>Service@nplus.zone</p>
        <p>
          If you have any particular questions, please don't hesitate to email
          us !
        </p>
      </div>

      <div class="share-box">
        <!-- todo:link -->
        <!-- <a href="https://discord.com/" target="_blank">
          <a-icon type="instagram" />
        </a> -->
        <a href="https://discord.gg/WwXW38jb" target="_blank">
          <img class="img-discord" src="./discord.png" />
        </a>
        <!-- todo:link -->
        <!-- <a href="https://weibo.com/" target="_blank">
          <a-icon type="medium" />
        </a> -->
        <a href="https://twitter.com/NplusOfficial/" target="_blank">
          <a-icon type="twitter" />
        </a>

        <!-- <a target="_blank">
          <span class="weixin"
            ><a-icon type="wechat" />
            <span class="qrcode">
              <vue-qr :size="404" :text="url"></vue-qr>
            </span>
          </span>
        </a> -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// import VueQr from "vue-qr";

import Footer from "../../components/Footer.vue";
export default {
  name: "Contact",
  components: {
    Footer,
    // VueQr,
  },
  data() {
    return {
      url: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2MDc3MDIwMA==&scene=124#wechat_redirect",
    };
  },
};
</script>

<style lang="scss" scoped>
#contact {
  overflow: hidden;
  background-color: #252525;
  background-image: url("@/assets/images/about/about-line.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // min-height: 100vh;
  // display: flex;
  // flex-direction: column;

  .contact-wrap {
    // flex: 1;
    min-height: calc(100vh - 490px);
    padding: 40px 0 80px 0;
    color: #ffffff;
    .contact-title {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
    }
    .contact-content {
      margin-top: 97px;
      // font-style: oblique;
      font-weight: 400;
      font-size: 18px;
      line-height: 35px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
      p:nth-child(1) {
        color: #fff;
      }
      p:nth-child(2) {
        color: #d1d1d1;
        margin-top: 79px;
      }
    }

    .share-box {
      margin-top: 40px;
      display: flex;
      align-items: center;
      a {
        margin-right: 40px;
        display: inline-block;
      }
      span {
        display: inline-block;
      }
      .img-discord {
        width: 40px;
        height: 28.5px;
      }
      ::v-deep .anticon {
        color: #fff;
        font-size: 40px;
      }
      .weixin {
        position: relative;
        .qrcode {
          position: absolute;
          top: 50px;
          left: 0;
          width: 404px;
          height: 404px;
          display: none;
        }

        &:hover .qrcode {
          display: block;
        }
      }
    }
  }
}
p {
  margin: 0;
  padding: 0;
}
</style>
