<template>
  <div id="policy">
    <div class="main-container mt_40">
      <div class="policy-title">Privacy Policy</div>
      <div class="policy-content">
        <p>
          nplus Labs Limited, a Hong Kong limited liability company (“nplus
          Labs”, "we", "us" or "our") recognizes the importance of protecting
          your Personal Data (as defined hereunder). This Privacy Policy
          (“Policy”) describes how nplus Labs collects, processes, uses,
          disseminates, or makes available your personal data via our website
          (nplus.com) (“Website”) and other means (i.e, social media, cookies,
          etc.).

          <br />
          <br />
          What Is Personal Data?
          <br />
          For this Policy, Personal Data means and includes your name, phone
          number, address, email address, online identifiers, and any other data
          which, directly or indirectly, can identify you.
          <br />
          <br />
          <br />
          Personal Data We Collect
          <br />
          Information Voluntarily Provided to Us. We collect your Personal Data
          and other information when you visit our website, place orders, make
          purchases, sign up or create an account with us, when you interact
          with our customer service for support or giving us feedback, or when
          you subscriber/participate in our survey/review or marketing
          activities (e.g., subscribing to our newsletter/mailing list,
          participating in our loyalty programs, contests and sweepstakes,
          etc.). The Personal Data obtained and collected by us includes:
          <br />
          <br />
          <br />
          Contact information (i.e, your name, phone number, postal address,
          email address, birthday, and any other personal data). Details of
          purchase/order your made and your purchase history as applicable, your
          payment information, such as billing address, credit card information
          and other information required for placing order or to complete the
          purchase. Your consumer behaviors and habits. Information and reason
          of contacting our customer service, and communication content between
          you and our customer service
          <br />
          <br />
          <br />

          Information Automatically Collected by Us. When you visit our Website,
          or when you interact with our emails or advertisements on our Website,
          or other sites, certain information are automatically collected by
          cookies or other tracking technologies:
          <br />
          <br />
          <br />

          Internet protocol (IP) address and other device identifiers
          <br />
          <br />

          Your login information, browser type, browser plug-in types, operation
          system, time zone setting. Information about your visit of our website
          pages (e.g., products you searched or viewed for, the amount of time
          you spend on each page, crashes and download errors and response
          times). For more details, please refer to the Cookies Policy for more
          details. You may adjust your browser settings to decline cookies or
          limit certain tracking. However, such change, adjustment or limitation
          may impact your experience of certain services/features on our
          website, or you may not be able to take full advantage of our
          offerings.
          <br />
          <br />

          Information Collected from Third Party Sources. We also obtain and
          collect certain of your information from third party sources,
          including social media, third party apps and similar technologies. The
          information may include your name, username, postal address, phone
          number, email address, demographic information, habits, preference,
          interests and any other publicly observed data.
          <br />
          <br />

          Combination of Information. We may combine the information we collect,
          obtain, or receive from and above you set forth in clauses 2.1 to 2.4
          above.
          <br />
          <br />

          How We Use Your Personal Data
          <br />
          To Perform a Contract with You. We use and process your Personal Data
          to perform a contract with you and carry out our contractual
          obligations, including processing the order(s) you have placed and the
          payments, or take step that you have requested to enter a contract.
          <br />
          <br />

          For Our Legitimate Interest. We use and process your Personal Date for
          our legitimate interests, and our interests are not overridden by your
          interests, fundamental rights, or freedoms. Our legitimate interests
          include:
          <br />
          To manage your registered account(s) or membership with us
          <br />
          To run and develop our business activities
          <br />
          To pursue and conduct general marketing and promotional activities
          <br />
          To provide you with our customer services, such as after-sale service,
          answering your queries, and providing you with the required products,
          services, or information
          <br />
          To invite your feedback to improve our products, service, and Website
          <br />
          To conduct research, review, and analysis
          <br />
          To manage and administer our accounts and record
          <br />
          To prevent or detect fraud and abuses of our website
          <br />
          <br />
          <br />
          Based On Your Consent. In addition, based on your consent, we also use
          and process your Personal Date for other marketing purpose:
          <br />
          To communicate with you on updates about our products
          <br />
          To deliver the type of product that may be of interest to you,
          including tailored advertisements
          <br />
          To personalize and customizing your website experience
          <br />
          <br />
          <br />
          Who And How We Share Your Personal Data?
          <br />
          We do not sell your Personal Data. We do share your Personal Data as
          follows:
          <br />
          <br />

          Authorized Personnel. Within nplus Labs, only the duly authorized
          personnel would have access to your Personal Data as necessary to
          perform duties for the purposes set forth in clause 2 above.
          <br />
          <br />

          Other Third Parties. In addition, we share your Personal Data to the
          following third parties:
          <br />
          Service Providers, including service providers for (a) web hosting,
          analytics, and related services, (b) payment processing, (c) order
          fulfilment and shipping, (d) fraud protection and credit risk
          reduction, and (e) marketing services on our behalf. The service
          providers are obligated contractually not to use, share, or disclose
          your Personal Data unless as necessary to perform the services on our
          behalf or to comply with the applicable laws and regulations.
          <br />
          Business Partners with whom we may collaborate in offering offer
          products
          <br />
          Advertising Partners, who will be allowed to set cookies or other
          tracking tools to collect information regarding your activities on our
          website. We may also share with them certain selected personal
          information, such as purchase records and demographic information.
          They may use the information collected for “interest-based
          advertising” and “online behavioral advertising, i.e., to deliver
          tailored or targeted advertisings to you on other websites.
          <br />
          <br />

          Trusted Third Parties, where disclosure is necessary (a) to protect
          the security or integrity of our databases or our Website; (b) for
          legal proceedings (e.g., legal action against pirated, counterfeit or
          unauthorized products) and to take precautions against legal
          liability; (c) in connection with the management of our business and
          operations; (d) in the event of a sale, merger, reorganization, change
          of control or any other similar event, to the extent permitted by
          applicable law; or (e) as required by applicable law or regulations,
          to the extent compatible with this Privacy.
          <br />
          <br />
          <br />

          Transfer of Personal Data
          <br />
          Outside Of the Origin Country. Your Personal Data may be transferred
          outside of the country from which it was originally collected and may
          be shared both intra-group and with third parties located in other
          jurisdictions, which may not have similar data protection laws. All
          such transfers of Personal Data will be pursuant to the applicable
          data protection laws.
          <br />
          <br />

          Outside Of EEA. For residents in the European Economic Area (“EEA”),
          we implement appropriate safeguards for the transfer of your Personal
          Data outside of the EEA, such as an appropriate data transfer
          agreement (based on the EU Commission Standard Contractual Clauses for
          the transfer of data to third countries (Article 46, 2., (c) of the
          GDPR, as of 25 May 2018)) or binding corporate rules (Article 47 of
          the GDPR as of 25 May 2018). All Personal Data so transferred should
          have adequate protection in accordance with applicable data protection
          rules.
          <br />
          <br />

          Security And Retention
          <br />
          Security. We place great importance on and are committed to protect
          the security of your Personal Data. We take appropriate technical and
          organizational measures to protect your Personal Data against
          accidental loss, unlawful or accidental destruction, alteration,
          unauthorized disclosure, or access. However, there is no electronic
          transmission or storage of information is 100% secure. While we will
          use commercially reasonable effort to protect your personal
          information, we cannot guarantee its absolute security and any
          transmission of your personal information through the internet is at
          your own risk. For the best possible protection of your personal data
          and information, we recommend that your device should be protected
          (i.e., the latest antivirus systems) and your internet service
          provider should take appropriate measures for the security of network
          data transmission (i.e., firewalls and anti-spam filtering).
          <br />
          <br />

          Retention. We retain your Personal Data for the time necessary to
          achieve the purposes set forth in this Policy, and in any event within
          the legal limitation periods under the applicable laws.
          <br />
          <br />

          Your Rights with Respect To Your Personal Data
          <br />
          You have the rights to:
          <br />
          <br />

          Get a copy of your Personal Data
          <br />
          Access, correct and update your Personal Data
          <br />
          Restrict our use and processing of your Personal Data
          <br />
          Object our use and processing of your Personal Data for direct
          marketing and other purpose based on a “legitimate interest”
          <br />
          Request to delete your Personal Data
          <br />
          Withdraw a consent given to us previously
          <br />
          Not to be subject to a decision based solely on automated processing
          <br />
          Make a complaint against us with your local data protection
          supervisory authority
          <br />
          You may exercise any of these rights by sending an email to us
          (support@nplus.zone) with a subject of “Personal Data Right”. We will
          take step to verify your identity, please provide us with your name,
          email address and evidence of your identity. We may also contract you
          asking for certain information for verification purpose. We will honor
          your above requests free of charge, however, to the extent permitted
          by law, we reserve the right to charge a reasonable fee or not to act
          on the request in case of the request is manifestly unfounded or
          excessive.
          <br />
          <br />

          Links To Social Media Platforms and Other Sites
          <br />
          Social Media Platforms. Our website contains social media plug-ins,
          including Instagram, Facebook, Twitter, Pinterest, and Snapchat. If
          you interact with these plug-ins, your activity on our website will
          also be made available to those social media platforms. We do not have
          control of these social medial platforms and do not endorse or make
          any representations about the third-party websites/applications, we
          are not responsible for their privacy practices or content. We
          encourage you to read the privacy policies of such social media
          platforms with which you interact.
          <br />
          <br />
          <br />
          Other Sites. Our website may also contain hyperlinks to other
          third-party website/applications which are not controlled or operated
          by us. The hyperlinks are for your reference and convenience only. We
          do not endorse or make any representations about the third-party
          websites/applications and are not responsible for their privacy
          practices or content. We encourage you to read the privacy policies of
          any website/application you visit before you visit the
          website/application or provide your Personal Data to them.
          <br />
          <br />

          Children's Privacy Protection
          <br />
          We are concerned about the safety of children when they use internet.
          Our services and Website are not directed to children under the age of
          13, and we do not knowingly collect personal information from children
          under the age of 13. Children should obtain their parent’s consent
          before giving out their personal information. If we are made aware of
          that personal information of a child under the age of 13 (or the age
          of 16 in certain EU member countries) are collected without verifiable
          parental consent, we will delete such information from our files as
          soon as possible and cease and terminate the child’s register
          account/membership. If you are concerned of your child’s use of our
          website, we recommend you to use web-filtering technology to supervise
          your child’s access to our Website.
          <br />
          <br />

          If you learn that your child has provided us with personal information
          without your consent, please alert us at support@nplus.zone with a
          subject of “Children’s Privacy Protection”.
          <br />
          <br />

          California Residents
          <br />
          Please refer to Privacy Statement for California Residents
          <br />
          <br />

          Last Modified Date and Change
          <br />
          This version of Policy is last modified and effective on 13 July 2021.
          We may from time to time change or modify our Policy to reflect
          changes to our privacy practices. If there is any modification or
          change, we will update the latest version which will be effective from
          the Last Modified Date. For any material changes, we will post a
          prominent notice on our website, and where required by applicable law,
          we may also notify you of such material changes and seek your consent.
          <br />
          <br />

          Questions And Concerns
          <br />
          If you have any queries, concerns, complaints, feedback, or comments
          regarding this Policy, please contact our Data Protection Officer at
          support@nplus.zone. We will endeavor to respond to your request and
          resolve your concerns as soon as possible.
          <br />

          Cookies Policy
          <br />

          <br />
          Last Modified Date: 14 June 2022
          <br />
          <br />

          This Cookies Policy forms part of the Privacy Policy. This cookies
          policy describes which cookies are used on nplus Labs website
          (nplus.zone) (“Website”), which is operated by nplus Labs Limited, a
          Hong Kong limited liability company (“nplus Labs”, "we", "us" or
          "our"), and how you can manage the cookies setting.
          <br />

          <br />
          What Is Cookie. A cookie is a small data file sent to your browser and
          stored on your computer's hard drive, mobile phone or other device.
          The “first party cookies” are the cookies set by the website owner,
          and are used to make the website work, and work more efficiently, also
          to provide reporting information. The “third party cookies” are the
          cookies set by other third parties, which allows third party
          features/functionality to be functioned on our website, such as
          website analytics and advertising. Also, they can recognize your
          device when you visit or interact with our website and when you visit
          or interact with other sites. Cookies can also classify as “session
          cookies” which would be deleted automatically when you close the
          website browser, and “persistent cookies” which would last for more
          than one visit. The sites with these cookies can recognize you when
          you re-open your browser.
          <br />
          <br />

          Types Of Cookies We Use.
          <br />
          Strictly Necessary and Functional Cookies – These cookies are
          necessary for basic site functionality and to provide you with
          services on our website, i.e., allowing your access to our Website,
          identifying you when you sign in your registered account, allowing
          your use of Website functions such as shopping carts and securing
          customer account pages. If you choose to disable these cookies, you
          will be unable to place order. These cookies are first party and
          session cookies.
          <br />
          <br />

          Performance And Functionality Cookies – These cookies are not strictly
          necessary but can enhance the Website’s performance and functionality
          by tracking usage on the Website. For example, they allow us to
          remember your site preference. If you choose to disable these cookies,
          certain functions such as the personalized features may become
          unavailable. These cookies are also first party cookies and can be
          session or persistent.
          <br />
          <br />

          Analytics and customization cookies – These cookies collection
          information about how you use the Website, such as which pages you
          visit, and help us customize the Website for you. They also include
          analytics allowing us to obtain statistics about website access and
          the effectiveness of our marketing campaigns. The information so
          collected is aggregated and no personal identification information
          would be collected by these cookies. These cookies are mix of first
          party and third party cookies and can be session or persistent.
          <br />
          <br />

          Advertising And Marketing Cookies – These cookies collect information
          to create tailored advertising to your interest on our website, social
          media platforms or other sites. They identify your preferences and
          analyze your online interactions with us. If you choose to disable
          these cookies, it may result in receiving advertising that is not as
          relevant to you. These cookies are mix of first party and third party
          cookies and can be session or persistent.
          <br />
          <br />

          Social Media Cookies – These cookies enable you to connect to third
          party social media networks and share content from our website through
          to the social media networks. If you choose to disable these cookies,
          you will be unable to connect to the social media networks effectively
          and to share content. These cookies are mix of first party and third
          party cookies and can be session or persistent.
          <br />
          <br />
          <br />

          Google Analytics. We may use Google Analytics to collect information
          about how you use our website. The information generated by the
          cookies about your use of our website, such as time, place, and
          frequency of your visits to our website, including your IP address,
          will be transmitted to Google’s servers in the United States and
          stored there. Google will use this information on our behalf to
          analyze your use of our website, compile reports regarding activities
          on our website and other internet usage. The information that your
          browser conveys within Google Analytics will not be merged and
          associated with any other data held by Google. You may at any time
          opt-out from being tracked by Google Analytics. For more details,
          please visit: http://tools.google.com/dlpage/gaoptout.
          <br />
          <br />

          How To Manage Your Cookies. You can set your browser for notification
          when you receive a cookie, you can then decide accepting it or not.
          Also, you always have the option to accept or decline the use of
          cookies by changing your web browser settings, and you may also delete
          cookies. Currently, our website does not respond to browser “Do Not
          Track” signals.
          <br />
          <br />

          Here are the links of certain browsers with details of the cookies
          setting:
          <br />
          <br />

          Chrome
          <br />
          Firefox
          <br />
          Internet Explorer 7 and 8
          <br />
          Internet Explorer 9
          <br />
          Safari
          <br />
          Opera
          <br />

          <br />
          You may learn how to delete cookies at
          http://www.allaboutcookies.org/; and how to disable third party
          cookies and advertising or targeting cookies on your computer at
          http://youronlinechoices.com/.
          <br />

          <br />
          Please be aware that if you disable the cookies completely, our
          website may not be able to function properly, and it may impact your
          experience of certain services/features, or you may not be able to
          take full advantage of our offerings.
          <br />
          <br />

          Privacy Statement for California Residents
          <br />

          <br />
          If you are a California resident, please also note this additional
          statement which is supplemental to our main Privacy Policy. As
          required under the California Consumer Privacy Act or (“CCPA”), this
          statement will share with your certain additional information relating
          to our privacy practice.
          <br />
          <br />

          Categories Of Personal Information We Collected.
          <br />
          <br />

          Identifiers, for example, name, email address, billing and shipping
          address and phone number
          <br />
          Commercial information, for example, purchase habits and purchase
          history
          <br />

          <br />
          Personal information categories, for example, physical characteristics
          or description and payment information.
          <br />

          <br />
          Geolocation data, for example, precise physical location such as IP
          address, billing address and shipping address. Internet and other
          similar network activity, for example, consumer’s interaction with a
          website, application, or advertisement
          <br />
          <br />

          Visual information, for example, the pictures/images you upload to our
          website
          <br />
          <br />

          Professional or employment-related information, for example, from
          employment candidates
          <br />
          Inferences drawn from other personal information, for example,
          consumer profiles reflecting certain preferences, characteristics, and
          behavior
          <br />
          <br />

          Business or Commercial Purposes of Collecting and Use of Your Personal
          Information. Please see Section 3 of our Privacy Policy
          <br />
          <br />

          Source Of Personal Information. Please see Section 2 of our Privacy
          Policy.
          <br />
          <br />

          Disclosure Of Personal Information. We do not sell your personal
          Information. CCPA defines “sale” of personal information as exchange
          of personal information for money or valuable consideration. In the
          preceding 12-month period, we do share the categories of personal
          information we collected (as set forth in Section A above) with third
          parties for business or commercial purpose. The third parties include
          service providers, business partners, advertising partners and other
          trusted third parties. Please see Section 4 of our Privacy Policy for
          more details.
        </p>
      </div>
      <a-back-top />
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
export default {
  name: "Policy",
  components: {
    Footer,
  },
};
</script>

<style scoped lang="scss">
#policy {
  .policy-title {
    // font-family: "Impact";
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    color: #ffffff;
    // padding-left: 100px;
  }
  .policy-content {
    // padding: 0 100px;
    margin-top: 79px;
    margin-bottom: 120px;
    p {
      // font-family: "Inter";
      font-weight: 300;
      font-size: 22px;
      line-height: 35px;
      color: #d1d1d1;
    }
  }
}
</style>
