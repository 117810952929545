import * as API from "@api/request";
import { convertBase64UrlToBlob } from "@/utils/tools";
export async function dataUrlToCdn(data) {
  let images = [];
  if (data.length === 0) {
    return images;
  }
  for (let item of data) {
    const f = convertBase64UrlToBlob(item.slice(20));
    if (f.size >= 20971520) {
      alert("Images larger than 20m cannot be uploaded!");
      throw Error;
    }
    const form = new FormData();
    form.append("file", f, new Date().getTime() + ".png");

    let imgRes = await API.excute("uploadFile", form, {
      isUploadFile: true,
    });
    images.push(imgRes.url);
  }
  return images;
}

export async function imgListToCdn(list) {
  let images = [];
  if (list.length === 0) {
    return images;
  }
  let form = new FormData();
  for (let item of list) {
    const f = convertBase64UrlToBlob(item.slice(20));
    if (f.size >= 20971520) {
      alert("Images larger than 20m cannot be uploaded!");
      throw Error;
    }
    form.append("file", f, new Date().getTime() + ".png");
  }
  let { data } = await API.excute("batchUploadFile", form, {
    isUploadFile: true,
  });
  data.map((item) => {
    images.push(item.url);
  });
  return images;
}
