/**
 * api request
 * Author: Thompson
 */
import apiName from "./apiName";
import axios from "./fetch";
import { getLocalStorage } from "@u/storage";
import { login } from "@/service/login";

// 统一处理请求接口的URL
export function url(url, config = {}) {
  const defaultParams = {
    dir: process.env.VUE_APP_API_PATH,
  };

  const mergeParams = { ...defaultParams, ...config };
  const domain = process.env.VUE_APP_API_URL;
  return (
    (mergeParams.domain ? mergeParams.domain : domain) + mergeParams.dir + url
  );
}

// 统一处理请求参数
export function mergeParams(data, config = {}) {
  const defaultConfigParams = {};
  const mergeConfigParams = { ...defaultConfigParams, ...config };
  const mergeParams = { ...mergeConfigParams, ...data };
  delete mergeParams.method;
  return mergeParams;
}

// api调用
export async function excute(name, data, config = {}) {
  const defaultConfigParams = {
    method: "post",
  };
  const aName = name.indexOf("/") === -1 ? apiName[name] : name;
  const mergeConfigParams = { ...defaultConfigParams, ...config };
  const authApis = [
    "getMyNfts",
    "addressList",
    "addressAdd",
    "addressUpdate",
    "orderList",
  ];
  if (authApis.includes(name)) {
    // 验证用户是否登录
    if (!getLocalStorage("Access_Token")) {
      await login();
    }
  }
  const headerInfo = {
    "session-token": getLocalStorage("Access_Token"),
  };

  let requestData = mergeParams(data, mergeConfigParams);

  if (mergeConfigParams.isUploadFile) {
    Object.assign(headerInfo, { "Content-Type": "multipart/form-data" });
    requestData = data;
  }
  const options = {
    url: url(aName, config),
    method: mergeConfigParams.method,
    headers: headerInfo,
    [mergeConfigParams.method === "get" || mergeConfigParams.method === "put"
      ? "params"
      : "data"]: requestData,
  };
  return axios(options);
}
