import { setLocalStorage } from "@u/storage";

import { walletWeb3modal } from "@u/wallet_web3modal";
import * as API from "@api/request";

export const login = async () => {
  await walletWeb3modal.onConnect();
  // 如果用户token信息失效，需要重新登录
  const signInfo = await walletWeb3modal.sign();
  const loginInfo = await API.excute("login", {
    message: `Login_at:${signInfo.message}`,
    wallet_address: signInfo.wallet_address,
    signature: signInfo.signMessage,
  });
  setLocalStorage("Access_Token", loginInfo.session_token);
};
