<template>
  <div class="footer">
    <div class="main-container footer-content">
      <div class="flex-space">
        <div style="text-align: left">
          <div class="footer-left-1">NPLUS</div>
          <div class="footer-left-2">Service@nplus.zone</div>
        </div>
        <div class="footer-right">
          <div
            @click="goPage(item)"
            v-for="(item, index) in menuList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <hr />
      <div class="flex-space mention">
        <div>&copy;nplus 2022</div>
        <div>
          <a target="_blank" href="https://beian.miit.gov.cn/"
            >粤ICP备2022034887号-2 深圳市恩加传媒科技有限公司版权所有</a
          >
        </div>
        <div>powered by NPLUS</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      menuList: [
        {
          name: "Home",
          path: "/",
        },
        {
          name: "Gallery",
          path: "/gallery",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "Terms and Conditions",
          path: "/conditions",
        },
        {
          name: "Privacy Policy",
          path: "/policy",
        },
        {
          name: "Doc",
          path: "/policy",
        },
      ],
    };
  },
  methods: {
    goPage(item) {
      if (item.name === "Doc") {
        window.open(`https://nplus-zone.gitbook.io/nplus-docs/`);
        return;
      }
      this.$router.push(item.path);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/static/css/font.scss";
.footer {
  height: 490px;
  background: #2f2f2f;
  a {
    color: rgba(255, 255, 255, 0.63);
  }
  .footer-content {
    // padding: 120px 100px 0 100px;
    padding-top: 120px;
    .flex-space {
      display: flex;
      justify-content: space-between;
      margin-bottom: 31px;
      .footer-left-1 {
        font-family: "PangMenZhengDao";
        font-weight: 400;
        font-size: 36px;
        line-height: 35px;
        background: linear-gradient(
          136deg,
          #e9e9e9 1.69%,
          #bbbbbb 28.71%,
          #ffffff 76.09%,
          #ffffff 77.84%,
          #c8c8c8 96.58%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .footer-left-2 {
        // font-family: "Inter";
        font-weight: 300;
        font-size: 18px;
        line-height: 40px;
        letter-spacing: 0.02em;
        color: #d1d1d1;
      }
      .footer-right {
        // font-family: "Inter";
        font-weight: 600;
        font-size: 18px;
        line-height: 40px;
        text-align: right;
        letter-spacing: 0.02em;
        color: #d1d1d1;
        cursor: pointer;
      }
    }
    .mention div {
      margin-top: 10px;
      // font-family: "Inter";
      font-weight: 300;
      font-size: 16px;
      line-height: 40px;
      // letter-spacing: 0.3em;
      color: rgba(255, 255, 255, 0.63);
    }
  }
}
</style>
