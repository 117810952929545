<template>
  <div @scroll="onScroll" ref="scrollRef" class="scroll-container">
    <div class="image-wrapper">
      <div
        :key="index"
        @click="handleChoose(item.nft, index, item.price)"
        v-for="(item, index) in info"
        class="nftItem"
        :class="[{ 'nftItem-active': selectedNFT === index }]"
      >
        <div
          class="nftItem-contanier"
          :class="[{ isActive: selectedNFT === index }]"
        >
          <!--  :class="[{ 'nft-mark': type === 'commercial' }]" -->
          <div
            class="nft-img"
            :style="{ backgroundImage: 'url(' + item.nft.image + ')' }"
          >
            <div :class="[{ 'nft-mark': type === 'commercial' }]">
              <div class="nft-layout">
                <!--   <img
                  class="nft-icon"
                  src="@/assets/images/customize/price.png"
                  alt="nft icon"
                /> -->
                <div v-show="type === 'commercial'" class="nft-price">
                  <span class="nft-text"> {{ item.price }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="nft-desc" v-show="(item.price + '').length > 5">
          {{ item.price }}
        </div>
      </div>
    </div>
    <div class="loading-box" v-show="isLoading"><a-spin :spinning="isLoading"></a-spin></div>
  </div>
</template>

<script>
import * as API from "@api/request";
export default {
  name: "NFTList",
  data() {
    return {
      info: [],
      selectedNFT: -1,
      hasNext: false,
      page: 1,
      isLoading: false,
    };
  },
  props: {
    type: {
      default: "",
      type: String,
    },
  },
  mounted() {
    this.getGalleryList();
  },
  methods: {
    handleChoose(item, index, price) {
      this.selectedNFT = index;
      this.$emit("handleChoose", item, index, price);
    },
    async getGalleryList() {
      const params = {
        gallery_type: this.type,
        paginate: {
          page: this.page,
          page_size: 20,
        },
      };
      this.isLoading = true;
      const { data, page_info } = await API.excute("getNFTlist", params);
      this.isLoading = false;
      this.info = this.info.concat(data);
      this.hasNext = page_info.has_next_page;
    },
    onScroll() {
      const scrollHeight = this.$refs.scrollRef.scrollHeight;
      const scrollTop = this.$refs.scrollRef.scrollTop;
      const distanceBottom = scrollHeight - scrollTop - 300;
      if (distanceBottom < 100 && !this.isLoading && this.hasNext) {
        this.page++;
        this.getGalleryList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./nftList.scss";
</style>
