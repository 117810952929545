/**
 * 电子钱包封装
 * Author: Thompson
 */
import { ethers } from "ethers";
import dayjs from "dayjs";
import { contract_address, contract_abi, chainId } from "./contract_config";
import { removeLocalStorage } from "@u/storage";
// import store from '@s'
class Wallet {
  // 构造函数
  constructor() {
    this.init();
  }

  // 切换网络后刷新钱包web3
  init() {
    this.getProvider();
    this.getSinger();
  }

  disconnect() {
    this.provider = null;
  }

  // 获取提供者
  async getProvider() {
    let currentProvider;
    if (window.ethereum) {
      currentProvider = window.ethereum;
    } else if (window.web3) {
      currentProvider = window.web3.currentProvider;
    } else {
      alert("系统没有检测到MetaMask插件！");
      return;
    }
    // 创建provider
    this.provider = new ethers.providers.Web3Provider(currentProvider);
  }
  async getProviderNow() {
    return this.provider;
  }
  // 获取当前可签名的钱包账号
  getSinger() {
    if (this.provider) {
      this.signer = this.provider.getSigner();
    }
  }

  // 切换网络
  async changeChain() {
    const res = await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
    console.log(res, "result");
  }
  // 链接MetaMask钱包
  async connectMetamask() {
    let accounts = null;
    try {
      accounts = await this.provider.send("eth_requestAccounts", []);
    } catch {
      accounts = null;
    }
    if (!accounts) {
      // 用户拒绝连接钱包，清除用户所有信息
      removeLocalStorage("user_info");
      removeLocalStorage("Access_Token");
      console.log("User rejected connect MetaMask.");
    }
    return accounts;
  }
  // 获取钱包地址
  async getAddress() {
    this.wallet_address = null;
    try {
      this.wallet_address = await this.signer.getAddress();
    } catch {
      this.wallet_address = null;
    }
    return this.wallet_address;
  }
  // 获取账户余额
  async getUserBalence() {
    const w_address = await this.getAddress();

    // const provider = new ethers.providers.Web3Provider(window.ethereum);
    // let a_balance = await provider.getBalance(w_address);
    // console.log(a_balance);
    // const balence_rst = await window.web3.eth.getBalance(w_address);
    // console.log(balence_rst, "balance2");
    const balence_rst = await this.provider.getBalance(w_address);
    return ethers.utils.formatEther(balence_rst);
  }
  // 钱包签名
  async sign() {
    if (this.provider) {
      const message = dayjs().valueOf();
      this.signMessage = await this.signer.signMessage(`Login_at:${message}`);
      return { message, signMessage: this.signMessage };
    }
  }
  // 连接已有合约
  connectContract() {
    this.contract = new ethers.Contract(
      contract_address,
      contract_abi,
      this.provider
    );
    return this.contract;
  }
  // 创建作为Signer连接的合同对象的新实例
  createSingerContract() {
    this.connectContract();
    this.singerContract = this.contract.connect(this.signer);
    return this.singerContract;
  }
  // 验证合约订单
  async checkContractOrder(orderId) {
    let checkOrder = null;
    this.connectContract();
    try {
      checkOrder = await this.contract.chekOrder(orderId);
      checkOrder = ethers.utils.formatEther(checkOrder);
    } catch {
      checkOrder = false;
    }
    return checkOrder;
  }
  // 支付订单
  async paySingerContractOrder(orderId, price) {
    let payInfo = null;
    this.createSingerContract();
    try {
      payInfo = await this.singerContract.payOrder(orderId, {
        value: ethers.utils.parseEther(price),
      });
    } catch (error) {
      payInfo = null;
    }
    return payInfo;
  }
}

export const wallet = new Wallet();
