const SAVE_ORDER = "SAVE_ORDER";

const state = {
  orderInfo: [],
};

const mutations = {
  [SAVE_ORDER](state, data) {
    let orderArr = [];
    orderArr.push(data);
    state.orderInfo = orderArr;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
