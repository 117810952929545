<template>
  <div id="about">
    <div class="main-container about-wrap">
      <p class="about-title">ABOUT</p>
      <div class="about-content">
        <p>
          nplus aims to integrate & merge the conventional ways of creating and
          consuming intellectual properties (IPs) with the recently emerged
          blockchain technology called Non-Fungible-Token and off-chain
          commercial operating opportunities. We will be responsible for
          discovering & maintaining potential IPs, extracting valuable content,
          publishing related NFTs and monetising both on-and-off chain business
          opportunities.
        </p>
      </div>

      <div class="flex-vh-center">
        <a
          class="link-btn"
          href="https://nplus-zone.gitbook.io/nplus-docs/"
          target="_blank"
          >View Doc</a
        >
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
export default {
  name: "About",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
#about {
  overflow: hidden;
  background-color: #252525;
  background-image: url("@/assets/images/about/about-line.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .about-wrap {
    flex: 1;
    padding: 40px 0 80px 0;

    color: #ffffff;
    .about-title {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
    }
    .about-content {
      display: flex;
      align-items: center;
      padding: 80px;
      p {
        // font-style: oblique;
        font-weight: 400;
        font-size: 18px;
        line-height: 35px;
        text-align: justify;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
        letter-spacing: 0.1em;
      }
    }
    .link-btn {
      color: #fff;
      font-size: 20px;
      width: 150px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 13px;
      border: 1px solid #fff;
    }
  }
}
p {
  margin: 0;
  padding: 0;
}
</style>
