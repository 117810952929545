import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import order from './modules/order'

Vue.use(Vuex)

const userState = createPersistedState({
  key: 'user_info',
  // storage: window.localStorage,
  // render(state) {
  //   return {...state}
  // },
  // paths: ['userInfo']
})

export default new Vuex.Store({
  modules: {
    user,
    order
  },
  plugins: [userState]
})