<template>
  <div id="orders">
    <div class="main-container orders-wrap">
      <div class="orders-head">
        <p><img class="icon-r" src="@/assets/icon/icon-r.png" /></p>
        <p @click="goAddress" class="change-btn flex-vh-center">
          Change My Shipping Address
        </p>
      </div>

      <div class="user-info">
        <div class="user-main">
          <p class="text">Connected With MetaMask</p>
          <div class="flex-space-between user-address">
            <div class="address">
              {{ address | simplify }}
            </div>
            <div class="flex-space-between link-area">
              <div @click="copyAddress" style="margin-right: 80px">
                <a-icon type="copy" />Copy Address
              </div>
              <a
                :href="`https://etherscan.io/address/${address}`"
                target="_blank"
                ><a-icon type="diff" />View on Ethereum Explore</a
              >
            </div>
          </div>
        </div>
      </div>

      <div>
        <p class="title">Orders</p>
        <div class="orders-main">
          <ConfigProvider>
            <template #renderEmpty>
              <div class="orders-empty">
                <p class="empty-text">Your cart is currently empty.</p>
                <p @click="goDesign" class="customize-btn">start customizing</p>
              </div>
            </template>
            <a-table
              :columns="columns"
              :data-source="ordersList"
              :pagination="false"
              :loading="isLoading"
            >
              <div slot="product" slot-scope="imgUrl, record">
                <img
                  style="width: 123px; margin: 10px 0"
                  :src="record.special.options[0].image"
                  v-if="record.special"
                />
                <img v-else style="width: 123px" :src="imgUrl[1]" />
                <img
                  v-show="record.type === 'clothes'"
                  style="width: 123px"
                  :src="imgUrl[3]"
                />
              </div>

              <div slot="name" slot-scope="record">
                <template v-if="record.special">
                  <p>{{ `Special` }}</p>
                  <p class="product-name">
                    {{ `${record.special.category}(${record.size})` }}
                  </p>
                </template>
                <template v-else>
                  <p>{{ `Customize NFT ${record.type}` }}</p>
                  <p class="product-name">
                    {{
                      `${record.name}(${record.color}${record.size ? "," : ""}${
                        record.size
                      })`
                    }}
                  </p>
                </template>
              </div>
              <div
                slot="action"
                slot-scope="record"
                style="display: inline-block"
              >
                <a-spin :spinning="record.spinning">
                  <span
                    @click="handlePay(record)"
                    class="action-btn flex-vh-center"
                    :class="{ disabled: record.disabled }"
                    v-show="record.state === 'pending'"
                    >pay</span
                  >
                </a-spin>
                <span
                  @click="checkDelivery(record)"
                  class="action-btn flex-vh-center"
                  v-show="record.state === 'delivering'"
                  >check delivery</span
                >
              </div>
            </a-table>
          </ConfigProvider>
        </div>
      </div>

      <Address ref="addressAdd" :from="from" />
    </div>

    <Discount ref="discountRef" @goApply="toApply" />

    <a-modal v-model="visible" centered :footer="null">
      <div class="delivery-modal">
        <div class="flex-row">
          <div class="delivery-animation">
            <img src="@/assets/icon/icon-delivery-line.png" />
          </div>
          <div class="delivery-car">
            <img src="@/assets/icon/icon-delivery-car.png" />
          </div>
        </div>

        <p class="code-text">Your delivery code： {{ deliveryCode }}</p>
        <button class="copy-btn" @click="onCopy">Copy</button>
        <div @click="goOpen" class="open-btn">
          <span>open in a new tab</span>
          <img class="share-btn" src="@/assets/icon/icon-tab.png" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as API from "@api/request";
import { walletWeb3modal } from "@u/wallet_web3modal";
import { message, ConfigProvider } from "ant-design-vue";
import get from "lodash/get";
import { mapState } from "vuex";
export default {
  name: "Orders",
  components: {
    Address: () => import("@/components/Address.vue"),
    ConfigProvider,
    Discount: () => import("@/components/Discount.vue"),
  },
  data() {
    return {
      // locale: { emptyText: <a-empty /> },
      visible: false,
      isLoading: false,
      from: "orderRoute",
      ordersList: [],
      deliveryCode: "",
      showAddr: false,
      columns: [
        {
          title: "OrderID",
          dataIndex: "key",
          key: "key",
          align: "center",
        },
        {
          title: "Product",
          dataIndex: "product",
          key: "product",
          align: "center",
          scopedSlots: { customRender: "product" },
        },
        {
          title: "Name",
          // dataIndex: "name",
          key: "name",
          scopedSlots: { customRender: "name" },
        },
        {
          title: "Quantity",
          dataIndex: "number",
          key: "number",
          align: "center",
        },
        {
          title: "State",
          dataIndex: "state",
          key: "state",
          align: "center",
        },
        {
          title: "Operate",
          key: "action",
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    address() {
      return this.userInfo?.wallet_address;
    },
  },
  mounted() {
    this.gerOrderList();
    // this.checkPayment();
  },
  methods: {
    async gerOrderList() {
      this.isLoading = true;

      const params = {
        paginate: {
          page: 1,
          page_size: 100,
        },
      };
      const resp = await API.excute("orderList", params);
      this.ordersList = resp.data.map((item) => {
        return {
          key: item.id,
          product: item.images,
          number: item.quantity,
          state: item.order_status,
          name: item.goods?.name,
          price: item.goods?.price,
          size: item.remark,
          spinning: false,
          disabled: false,
          expressInfo: item?.express_info,
          type: item.goods?.category,
          color: item.goods_option?.name,
          nfts: item.nfts_info,
          special: item.special,
        };
      });
      this.isLoading = false;
    },
    goAddress() {
      // this.showAddr = true;
      this.$refs.addressAdd.open();
    },
    goDesign() {
      this.$router.push("/customize");
    },
    checkDelivery(record) {
      this.deliveryCode = record.expressInfo.order_num;
      this.visible = true;
    },
    onCopy() {
      this.copyFun(this.deliveryCode);
    },
    copyAddress() {
      this.copyFun(this.address);
    },
    copyFun(val) {
      this.$copyText(val).then(
        () => {
          this.$message.success("Copied to Clipboard");
        },
        (err) => {
          console.log(err, "error");
        }
      );
    },
    goOpen() {
      //   window.location.href = "https://www.baidu.com/";
      window.open(`https://www.kuaidi100.com`);
    },
    async checkPayment(id) {
      const res = await API.excute("checkPayment", { order_ids: [id] });
      console.log(res);
    },
    toApply(param) {
      console.log("apply", param);
      this.iptCode = param;
      this.$refs.discountRef.close();
      this.handlePay(this.infoData);
    },
    goPay(data) {
      this.infoData = data;
      this.$refs.discountRef.open();
    },
    async handlePay(data) {
      console.log(data, "data");
      if (data.disabled) return;
      data.spinning = true;
      let nftTotal = data.nfts.reduce((acc, cur) => {
        return acc + +get(cur, "price", 0);
      }, 0);

      await walletWeb3modal.onConnect();
      await walletWeb3modal.changeChain();

      let totalPrice = nftTotal + +data.price;
      if (data.special) {
        totalPrice = data.number * data.special.price;
      }

      const paiedValue = await walletWeb3modal.checkContractOrder(data.key);
      console.log(totalPrice, "totalPrice");
      console.log(paiedValue, "paiedValue");
      if (paiedValue >= totalPrice) {
        // 已经支付
        data.disabled = true;
      } else {
        // 继续支付
        const payInfo = await walletWeb3modal.paySingerContractOrder(
          data.key,
          totalPrice.toString()
        );
        if (payInfo) {
          // 已经支付
          const payRst = await payInfo.wait();
          console.log(payRst);
          this.checkPayment(data.key);
          data.disabled = true;
        } else {
          message.error("Payment failed.");
        }
      }
      data.spinning = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/static/css/font.scss";
p {
  margin: 0;
}
a,
a:hover {
  color: #fff;
}
#orders {
  .orders-wrap {
    font-family: "Montserrat";
    margin-top: 42px;
    .orders-head {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .icon-r {
        width: 72px;
        height: 72px;
      }
      .change-btn {
        cursor: pointer;
        width: 370px;
        height: 38px;
        border: 2px solid rgba(220, 223, 230, 0.97);
        border-radius: 40px;
        font-weight: 700;
        font-size: 18px;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
    .user-info {
      width: 100%;
      height: 135px;
      border-radius: 20px;
      margin: 33px 0 58px 0;
      background-image: url("@/assets/images/orders/bg.png");
      background-repeat: no-repeat;
      background-position: right;
      // background: linear-gradient(
      //   91.52deg,
      //   rgba(255, 255, 255, 0.16) 13.89%,
      //   rgba(255, 255, 255, 0.195) 37.76%,
      //   rgba(255, 255, 255, 0.164834) 55.39%,
      //   rgba(255, 255, 255, 0.18) 65.16%,
      //   rgba(255, 255, 255, 0.255) 75.13%,
      //   rgba(255, 255, 255, 0.08) 92.82%,
      //   rgba(255, 255, 255, 0.195) 101.8%
      // );
      .user-main {
        padding: 20px 40px;
        .text {
          // font-family: "PingFang SC";
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;

          color: #9d9d9d;
        }
      }
      .user-address {
        margin-top: 15px;
        .address {
          font-weight: 800;
          font-size: 28px;
          line-height: 34px;

          color: #ffffff;

          text-shadow: 0px 4px 4px rgba(86, 86, 86, 0.2);
        }
        .link-area {
          cursor: pointer;
          // font-family: "PingFang SC";
          font-weight: 600;
          font-size: 18px;

          color: #ffffff;
          ::v-deep .anticon {
            margin-right: 15px;
          }
        }
      }
    }
    .title {
      font-weight: 700;
      font-size: 28px;
      line-height: 34px;
      letter-spacing: 0.02em;

      color: #ffffff;
    }
    .orders-main {
      ::v-deep .ant-table-body {
        .ant-spin {
          width: 210px;
        }
        .ant-spin-blur::after {
          display: none;
        }
      }
      margin-top: 27px;
      margin-bottom: 30px;
      // .orders-column {
      //   height: 82px;
      //   line-height: 82px;
      //   background: #2f2f2f;
      //   border-radius: 20px 20px 0px 0px;
      //   display: flex;
      //   justify-content: space-between;
      //   span {
      //     display: block;
      //     font-family: "Inter";
      //     font-weight: 700;
      //     font-size: 26px;
      //     color: #ffffff;
      //     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      //   }
      // }
    }
    .orders-empty {
      margin-top: 92px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .empty-text {
        // font-family: "Inter";
        font-weight: 500;
        font-size: 22px;

        color: #d1d1d1;
      }
      .customize-btn {
        width: 421px;
        height: 66px;
        line-height: 66px;
        text-align: center;
        background: linear-gradient(
          90deg,
          #6f6d6d 0%,
          #4c4d4d 38.99%,
          #565555 66.97%,
          #252627 105.02%,
          #9f9f9f 146.65%,
          #323334 162.76%
        );
        border: 1px solid rgba(119, 119, 119, 0.23);
        box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
        // font-family: "Inter";
        font-weight: 700;
        font-size: 20px;

        color: #ffffff;

        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: 26px;
        cursor: pointer;
      }
    }
  }

  ::v-deep .ant-table-body {
    .ant-table-thead > tr > th {
      height: 82px;
      line-height: 82px;
      background: #2f2f2f;
      padding: 0 45px;
      // font-family: "Inter";
      font-weight: 700;
      font-size: 18px;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-bottom: none;
    }
    .ant-table-tbody > tr > td {
      padding: 0 45px;
    }
    .ant-table-thead > tr:first-child > th:first-child {
      border-top-left-radius: 20px;
    }
    .ant-table-thead > tr:first-child > th:last-child {
      border-top-right-radius: 20px;
    }
    .ant-table-tbody {
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      .product-name {
        color: #d1d1d1;
        margin-top: 20px;
      }
      .action-btn {
        display: block;
        cursor: pointer;
        width: 210px;
        height: 38px;
        // line-height: 38px;
        // text-align: center;
        border: 2px solid rgba(220, 223, 230, 0.97);
        border-radius: 40px;
        font-weight: 700;
        font-size: 20px;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        &.disabled {
          color: #8c8c8c;
          border-color: #8c8c8c;
          cursor: not-allowed;
        }
      }
    }
    .ant-table-tbody
      > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
      > td {
      background: transparent;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 1px solid #d1d1d1;
    }
  }

  ::v-deep .ant-table-placeholder {
    background: none;
    border-top: none;
    color: #fff;
  }
}
::v-deep .ant-modal {
  .ant-modal-content {
    background: #2f2f2f;
    border-radius: 20px;
    .ant-modal-close {
      top: 28px;
      right: 26px;
      color: #fff;
      background: rgba(131, 131, 131, 0.7);
      border-radius: 50%;
      .ant-modal-close-x {
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .ant-modal-body {
    padding: 0;
    .delivery-modal {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px 0 53px 0;
      .delivery-animation {
        position: relative;
        margin-top: 20px;
        transform: translateX(-50%) translateY(-50%);
        animation: Group_399__x__1 0ms 0ms linear forwards infinite,
          Group_399__x__2 600ms 0ms linear forwards infinite;
        // Group_399__y__1 600ms 0ms linear forwards infinite;
      }
      .delivery-car {
        margin-left: 20px;
      }

      @keyframes Group_399__x__1 {
        from {
          left: 40px;
        }

        to {
          left: 45px;
        }
      }
      @keyframes Group_399__x__2 {
        from {
          left: 45px;
        }

        to {
          left: -15px;
        }
      }
      @keyframes Group_399__y__1 {
        from {
          top: 43.7466px;
        }

        to {
          top: 43.7466px;
        }
      }
      .code-text {
        // font-family: "Inter";
        font-weight: 400;
        font-size: 22px;
        line-height: 14px;

        color: #ffffff;
        // margin-bottom: 57px;
        padding: 20px 0;
      }
      .copy-btn {
        border: 1px solid #fff;
        width: 100px;
        height: 50px;
        border-radius: 100px;
        margin-bottom: 20px;
        background: none;
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }
      .open-btn {
        width: 290px;
        height: 66px;
        cursor: pointer;
        background: linear-gradient(
          90deg,
          #6f6d6d 0%,
          #4c4d4d 38.99%,
          #565555 66.97%,
          #252627 105.02%,
          #9f9f9f 146.65%,
          #323334 162.76%
        );
        border: 1px solid rgba(220, 223, 230, 0.23);
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          // font-family: "Inter";
          font-weight: 700;
          font-size: 20px;
          color: #ffffff;

          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        img {
          margin-left: 23px;
        }
        .share-btn {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
</style>
