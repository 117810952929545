<template>
  <div style="height: 80px">
    <header class="header">
      <div class="main-container header-p flex-space-between">
        <div class="flex-vertical-center">
          <!-- <div @click="goHome" class="logo">
            <img src="@/assets/images/home/logo.png" />
          </div> -->

          <!-- 菜单导航 -->
          <a-menu
            mode="horizontal"
            @click="menuClick"
            :defaultSelectedKeys="[$route.path]"
            :selectedKeys="[$route.path]"
          >
            <a-menu-item class="menu-home" :key="menuList[0].path">
              <div class="logo flex-vh-center">
                <img src="@/assets/images/home/logo.png" />
              </div>
            </a-menu-item>
            <a-menu-item
              v-for="(menu, index) in menuList.slice(1)"
              :key="menu.path"
              >{{ menu.name
              }}<img class="icon" v-if="menu.icon" :src="menu.icon"
            /></a-menu-item>
          </a-menu>
        </div>

        <div class="flex-vertical-center">
          <div @click="checkOrders" v-if="address" class="user-detail">
            <span class="user-balance">{{ `${balance}ETH` }}</span>
            <div class="user-address">
              <span>{{ address | simplify }}</span>
              <img src="@/assets/icon/icon-r.png" />
            </div>
          </div>
          <div class="logout" @click="logOut" v-if="address">Logout</div>

          <div v-else @click="goConnect" class="shopping-click">
            <a-spin :spinning="spinning">
              <div class="head-connect-btn">Connect wallet</div>
            </a-spin>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { walletWeb3modal } from "@u/wallet_web3modal";
// import { setLocalStorage } from "@u/storage";
export default {
  name: "SideBar",
  data() {
    return {
      spinning: false,
      openKeys: [],
      rootMenuKeys: ["/gallery", "/customize", "/about", "/contact"],
      menuList: [
        {
          name: "HOME",
          path: "/",
        },
        {
          name: "Gallery",
          path: "/gallery",
        },
        {
          name: "Customize",
          path: "/customize",
        },
        {
          name: "About",
          path: "/about",
        },
        {
          name: "Contact",
          path: "/contact",
        },
        {
          name: "Special",
          path: "/special",
          icon: require("@/assets/icon/hot.png"),
        },
      ],
      // userInfo: {},
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    address() {
      return this.userInfo?.wallet_address;
    },
    balance() {
      const money = this.userInfo?.wallet_balence;
      return Number(money).toFixed(4);
    },
  },
  methods: {
    logOut() {
      this.$confirm({
        title: "Do you want to logout?",
        centered: true,
        okText: "Confirm",
        onOk() {
          walletWeb3modal.resetApp();
        },
        onCancel() {
          console.log("Cancel");
        },
        class: "logout-modal",
      });
    },
    menuClick(param) {
      this.$router.push({ path: param.key });
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    async goConnect() {
      if (this.spinning) return;
      this.spinning = true;
      try {
        await walletWeb3modal.onConnect();
      } catch (e) {
        console.log(e);
      }
      this.spinning = false;
    },
    checkOrders() {
      this.$router.push("/orders");
    },
  },
  async mounted() {},
  created() {},
};
</script>

<style scoped lang="scss">
@import "./scss/header.scss";
</style>
