/**
 * 通用工具类
 * Author: Thompson
 */

// 非空检测
export function isNotEmpty(val) {
  if (!/\S/.test(val) || val === undefined || val === null) {
    return false;
  }
  return true;
}

export function convertBase64UrlToBlob(urlData) {
  let split = urlData.split(",");
  let bytes = window.atob(split[1]);
  let ab = new ArrayBuffer(bytes.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i);
  }
  return new Blob([ab], { type: split[0] });
}
