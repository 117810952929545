<template>
  <div id="order">
    <div class="main-container order-main" v-if="orderList.length > 0">
      <b class="head-title"
        ><b @click="backToCustomize" class="cursor">&lt; Customize / </b>
        <b @click="goBack" class="cursor"
          >Customize your {{ orderList[0].title }}</b
        >
        / <b style="color: #fff">Check out</b></b
      >

      <div v-show="!isSuccess">
        <p class="check-title">CHECK OUT</p>
        <div>
          <div class="check-product">
            <a-table
              :columns="columns"
              :data-source="orderList"
              :pagination="false"
              :row-key="
                (record, index) => {
                  return index;
                }
              "
            >
              <!-- product -->
              <div
                class="product-detail"
                slot="product"
                slot-scope="imgUrl, record"
                v-if="record.title"
              >
                <img style="width: 123px" :src="imgUrl[1]" />
                <img
                  v-show="record.title === 'clothes'"
                  style="width: 123px"
                  :src="imgUrl[3]"
                />
                <div class="product-design">
                  <p>{{ `Customize NFT ${record.title}` }}</p>
                  <p>{{ record.type }}</p>
                </div>
              </div>

              <!-- color/size -->
              <div
                class="product-option"
                slot="option"
                slot-scope="goodsSize, record"
              >
                <span v-if="record.goodsOption">{{
                  record.goodsOption.name
                }}</span>
                <span>{{ goodsSize ? `,${goodsSize}` : "" }}</span>
              </div>

              <!-- quanlity -->
              <div class="flex-vertical-center" slot="number" slot-scope="text">
                <span class="product-count">{{ text }}</span>
                <span class="flex-column">
                  <span @click="toAdd" class="flex-vh-center action-btn">
                    <a-icon type="caret-up" />
                  </span>
                  <span @click="toSubtract" class="flex-vh-center action-btn">
                    <a-icon type="caret-down" />
                  </span>
                </span>
              </div>
              <!-- subtoal -->
              <div slot="subtotal" slot-scope="price, record">
                <div class="price-wrap">
                  <img src="@/assets/icon/icon-eth.png" />
                </div>
                <span style="margin-left: 18px">{{
                  parseFloat(
                    (price * record.count + (record.nftsPrice || 0)).toFixed(10)
                  )
                }}</span>
              </div>
            </a-table>

            <!-- <div class="discount-area">
              <div class="discount-main">
                <p class="title">Discount Code：<span> (optional)</span></p>
                <a-input v-model="code" />
                <p @click="goApply" class="apply-btn">Apply</p>
              </div>
            </div> -->
          </div>
          <div class="footer-btn">
            <div class="btn-area">
              <span @click="goBack" class="btn-primary btn-back">Go Back</span>
              <span @click="goChoose" class="btn-primary btn-pay"
                >Choose Address</span
              >
            </div>
          </div>
        </div>
      </div>

      <Success v-show="isSuccess" @backToCustomize="backToCustomize" />
      <Address
        ref="addressAdd"
        @goBack="goBackCheck"
        @paySuccess="paySuccess"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "Check",
  components: {
    Address: () => import("@/components/Address.vue"),
    Success: () => import("../success/index.vue"),
  },
  data() {
    return {
      count: 1,
      isSuccess: false,
      columns: [
        {
          title: "Product",
          dataIndex: "imgUrl",
          key: "product",
          scopedSlots: { customRender: "product" },
        },
        {
          title: "Color/Size",
          dataIndex: "goodsSize",
          key: "color/size",
          align: "center",
          scopedSlots: { customRender: "option" },
        },
        {
          title: "Quanlity",
          dataIndex: "count",
          key: "quanlity",
          // align: "center",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "Subtotal",
          dataIndex: "price",
          key: "subtotal",
          align: "center",
          scopedSlots: { customRender: "subtotal" },
        },
      ],
      showAddr: false,
      code: "",
    };
  },
  computed: {
    orderList() {
      let orderData = this.$store.state.order.orderInfo;
      return orderData;
    },
  },
  methods: {
    ...mapMutations({
      saveOrder: "order/SAVE_ORDER",
    }),
    goBack() {
      this.isSuccess = false;
      this.count = 1;
      this.$emit("back");
      // this.$router.back();
    },
    backToCustomize() {
      this.isSuccess = false;
      this.$emit("backToCustomize");
    },
    paySuccess() {
      this.isSuccess = true;
    },
    goBackCheck() {
      this.showAddr = false;
    },
    goChoose() {
      let { price, count } = this.orderList[0];
      this.saveOrder({
        ...this.orderList[0],
        // count: this.count,
        total: parseFloat((price * count).toFixed(10)),
      });
      // this.showAddr = true;
      this.$refs.addressAdd.open();
    },
    toAdd() {
      this.count++;
      this.saveOrder({ ...this.orderList[0], count: this.count });
    },
    toSubtract() {
      if (this.count < 1) return;
      this.count--;
      this.saveOrder({ ...this.orderList[0], count: this.count });
    },
    goApply() {
      console.log("apply", this.code);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
p {
  margin: 0;
}
#order {
  .order-main {
    // padding: 0 194px 0 207px;
    font-family: "Montserrat";
    .cursor {
      cursor: pointer;
    }
    .head-title {
      // font-family: "Montserrat";
      margin-top: 36px;
      display: inline-block;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: rgba(255, 255, 255, 0.55);
      span {
        cursor: pointer;
      }
    }
    .check-title {
      margin-top: 20px;
      margin-bottom: 24px;
      font-size: 34px;
      color: #fff;
      // font-family: "Montserrat";
      font-weight: 700;
      letter-spacing: 0.02em;
    }
    .check-product {
    }
    .footer-btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 70px;
      .btn-area {
        display: flex;
        span {
          display: block;
        }
        .btn-primary {
          height: 40px;
          line-height: 40px;
          width: 224px;
          text-align: center;
          border-radius: 40px;
          color: #ffffff;
          // font-family: "Inter";
          font-size: 17px;
          font-weight: 700;
          cursor: pointer;
        }
        .btn-back {
          // border: 2px solid rgba(220, 223, 230, 0.97);
          margin-right: 12px;
          background: #666666;
        }
        .btn-pay {
          width: 222px;
          height: 38px;
          line-height: 36px;
          border: 2px solid rgba(220, 223, 230, 0.97);
        }
      }
    }
  }
}
:deep(.ant-table-body) {
  .ant-table-thead > tr > th {
    height: 82px;
    line-height: 82px;
    background: #2f2f2f;
    padding: 0 45px;
    font-weight: 700;
    font-size: 18px;
    color: #ffffff;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    padding: 0 45px;
  }
  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 20px;
    padding-left: 76px;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 20px;
  }
  .ant-table-tbody {
    // font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
    .anticon {
      font-size: 33px;
      cursor: pointer;
    }
    .product-detail {
      display: flex;
      align-items: center;
      .product-design {
        margin-left: 67px;
        // font-family: "Inter";
        font-weight: 700;
        font-size: 22px;
        line-height: 50px;
        color: #ffffff;
        p:nth-child(2) {
          // color: rgba(209, 209, 209, 1);
          color: #d1d1d1;
          font-size: 18px;
        }
      }
    }
    .product-option {
      background: #2f2f2f;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      padding: 0 15px;
      font-weight: 400;
      font-size: 18px;
    }
    .product-count {
      height: 40px;
      padding: 0 40px;
      background: #2f2f2f;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
    }
    .action-btn {
      width: 34px;
      height: 16px;
      background-color: #2f2f2f;
      margin-left: 5px;
      border-radius: 3px;
      margin-bottom: 5px;
      .anticon {
        font-size: 14px;
      }
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
    .price-wrap {
      width: 24px;
      height: 25px;
      display: inline-block;
    }
  }
  // 修改当前行hover样式
  .ant-table-tbody
    > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: transparent;
  }
}
</style>
