import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App.vue";
import router from "./router";
import VueClipboard from "vue-clipboard2";
import store from "../src/store";

import "../src/config/rem";

import "ant-design-vue/dist/antd.css";
import "animate.css";

import "@/filters";

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.use(Antd).use(VueClipboard);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
