export function textToImage(text, color) {
  let tLength = text.length; //获取文本个数
  let canvas = document.createElement("canvas"); //创建画布
  canvas.width = tLength * 100; //设置画布宽度，15为字体大小
  canvas.height = 200; //设置画布高度
  let cWidth = tLength * 100;
  let cHeight = 200;
  let context = canvas.getContext("2d"); //获取绘图环境
  let ratio = getPixelRatio(context);
  canvas.style.width = canvas.width + "px";
  canvas.style.height = canvas.height + "px";
  canvas.width = canvas.width * ratio;
  canvas.height = canvas.height * ratio;
  context.textAlign = "center"; //设置居中
  context.textBaseline = "middle";
  context.fillStyle = color === "white" ? "#fff" : "#000"; //设置背景色
  context.fillRect(0, 0, canvas.width, canvas.height); //绘制白色背景，rect为方形
  // context.strokeRect(0, 0, canvas.width, canvas.height); //绘制白色背景，rect为方形
  context.scale(ratio, ratio);
  context.font = "bold 100px Montserrat"; //加粗，字号，字体
  context.fillStyle = color === "white" ? "#000" : "#fff"; //设置字体颜色
  context.fillText(text, cWidth / 3, cHeight / 2); //添加文字
  return canvas.toDataURL("image/png"); //返回Base64
}
function getPixelRatio(context) {
  let backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1;
  return (window.devicePixelRatio || 1) / backingStore;
}
