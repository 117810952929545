import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../pages/home";
import Gallery from "@v/gallery";
import Customize from "../pages/customize";
import About from "../pages/about";
import Contact from "../pages/contact";
import Conditions from "../pages/conditions";
import Check from "../pages/customize/check";
import Orders from "../pages/orders";
import SuccessPage from "../pages/success";
import Policy from "../pages/policy";
import TestPage from "../pages/testPage";

import Special from "@v/special";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/gallery",
    name: "gallery",
    component: Gallery,
  },
  {
    path: "/customize",
    name: "customize",
    component: Customize,
    meta: {
      keepAlive: false,
    },
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },
  {
    path: "/contact",
    name: "contact",
    component: Contact,
  },
  {
    path: "/conditions",
    name: "conditions",
    component: Conditions,
  },
  {
    path: "/policy",
    name: "policy",
    component: Policy,
  },
  {
    path: "/check",
    name: "check",
    component: Check,
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders,
  },
  {
    path: "/success",
    name: "success",
    component: SuccessPage,
  },
  {
    path: "/special",
    name: "Special",
    component: Special,
  },
  {
    path: "/testapi",
    name: "TestApi",
    component: () => import("@v/ApiTestView"),
  },
  {
    path: "/test",
    name: "test",
    component: TestPage,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   // 跳转页面时，自动滚动到顶部
//   document.body.scrollTop = 0;
//   document.documentElement.scrollTop = 0;
//   next();
// });

// 目标跳转页面路径和当前页面路径一致，重复点击浏览器报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  // 跳转页面时，自动滚动到顶部
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
