/**
 * all api names
 * Author: Thompson
 */
export default {
  getUserAddressList: "/address/list",
  login: "/user/login",
  addressList: "/address/list",
  addressAdd: "/address/add",
  addressUpdate: "/address/update",
  checkPayment: "/order/check_payment",
  orderAdd: "/order/add",
  orderList: "/order/list",
  uploadFile: "/common/upload_file",
  batchUploadFile: "/common/batch_upload_file",
  goodsAdd: "/goods/admin/add",
  goodsList: "/goods/list",
  // Gallery Module NFT list
  getNFTlist: "/gallery/list",
  // gallery collection list
  getCollections: "/gallery/collection/list",
  // my nft list  prod: /nft/list
  getMyNfts: "/nft/list",
  // list nft
  listMyNft: "/gallery/share",
  // unlist nft
  unlistMyNft: "/gallery/unshare",
  // special list
  getSpecialList: "/special/list",
  // nft trade list
  getNFTTradeList: "/nft/trade_history",
  // 兑换码
  useRedeem: "/redeem_code/use",
};
