<template>
  <div id="list">
    <div class="main-container list-main">
      <div class="list-wrap">
        <div class="flex-space-between">
          <div class="head-wrap">
            <div class="head-title">GALLERY</div>
          </div>
          <div class="nav-right">
            <div
              class="icon-button"
              :class="{ more: showMore }"
              @click="getMoreList"
            ></div>
            <div class="head-my-btn" @click="listMyNFT">List my NFTs</div>
          </div>
        </div>
        <a-tabs :activeKey="activeTab" @change="callback">
          <a-tab-pane :tab="item.name" v-for="item in tabList" :key="item.key">
            <div class="pane-content">
              <div class="collection">
                <div class="collection-title">Collections</div>
                <a-input
                  v-model="keywords"
                  placeholder="Search"
                  @change="handleValueChange"
                >
                  <a-icon slot="prefix" type="search" style="color: #7c7c7c" />
                </a-input>
                <template v-if="collections.length">
                  <div
                    class="collection-item"
                    :class="{ checked: collection_checked === item.id }"
                    v-for="item in collections"
                    :key="item.id"
                    @click="handleCollectionChecked(item)"
                  >
                    <img :src="item.image_url" class="collection-image" />
                    <span class="oneline-ellipsis">{{ item.name }}</span>
                  </div>
                </template>
              </div>
              <list-item-view
                :listData="listData"
                :showMore="showMore"
                :activeTab="activeTab"
              ></list-item-view>
              <div class="clear"></div>
            </div>
          </a-tab-pane>
          <div class="tab-bar-words" slot="tabBarExtraContent">
            <a-select
              :value="sortType"
              style="
                width: 280px;
                background: rgba(88, 88, 88, 0.4);
                font-size: 17px;
              "
              @change="handleSortChange"
              dropdownClassName="tab-bar-dropdown"
            >
              <a-icon type="caret-down" slot="suffixIcon" />
              <a-select-option
                v-for="item in sortby"
                :value="item.value"
                :key="item.value"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </div>
        </a-tabs>

        <div class="nodata" v-if="listData.length === 0 && !spinning">
          <a-empty />
        </div>
        <Loading :tip="'loading...'" :spinning="spinning" />
        <a-back-top />
      </div>
    </div>
    <dialog-for-list
      ref="dialogForList"
      @listSuccess="listSuccess()"
    ></dialog-for-list>
    <!-- <Footer /> -->
  </div>
</template>

<script>
// import Footer from "@/components/Footer.vue";
import * as API from "@api/request";
export default {
  name: "Gallery",
  components: {
    // Footer,
    DialogForList: () => import("./components/ListDialog"),
    ListItemView: () => import("./components/ListItemView"),
    Loading: () => import("@/components/Loading"),
  },
  data() {
    return {
      loading: false,
      busy: false,
      total: 0,
      spinning: false,
      listData: [],
      hasNextPage: false,
      page: 1,
      pageSize: 40,
      showMore: false,
      tabList: [
        { key: "commercial", name: "Commercial" },
        { key: "cc0", name: "CC0" },
      ],
      activeTab: "commercial",
      tabBarTxt: {
        commercial:
          "NFTs listed here are all looking for commercial cooperation. ",
        cc0: "NFTs listed here are open source. ",
      },
      galleryType: "commercial",
      keywords: "",
      collections_origin: [],
      collections: [],
      collection_checked: "",
      sortType: "Sort By",
    };
  },
  computed: {
    sortby() {
      if (this.activeTab === "cc0") {
        return [
          { name: "All", value: "default" },
          { name: "Time", value: "time" },
        ];
      } else {
        return [
          { name: "All", value: "default" },
          { name: "Price low to high", value: "lowhigh" },
          { name: "Price high to low", value: "highlow" },
          { name: "Time", value: "time" },
        ];
      }
    },
  },
  mounted() {
    this.page = 1;
    window.addEventListener("scroll", this.windowScroll);
    this.getCollections();
    this.getNFTlist();
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  methods: {
    handleSortChange(value) {
      this.sortType = value;
      this.page = 1;
      this.getNFTlist();
    },
    handleValueChange() {
      this.collections = this.collections_origin.filter(
        (item) =>
          item.name.toLowerCase().indexOf(this.keywords.toLowerCase()) > -1
      );
    },
    async getCollections() {
      const { data } = await API.excute("getCollections", {
        gallery_type: this.activeTab,
        paginate: {
          page: 1,
          page_size: 100,
        },
      });
      this.collections = data;
      this.collections_origin = data;
    },
    callback(key) {
      this.activeTab = key;
      this.galleryType = key;
      this.page = 1;
      this.collection_checked = "";
      this.listData = [];
      this.collections = [];
      this.sortType = "Sort By";
      this.getCollections();
      this.getNFTlist();
    },
    handleCollectionChecked(item) {
      if (this.collection_checked === item.id) {
        this.collection_checked = "";
      } else {
        this.collection_checked = item.id;
      }
      this.page = 1;
      this.getNFTlist();
    },
    getMoreList() {
      this.showMore = !this.showMore;
    },
    listSuccess() {
      this.page = 1;
      this.getNFTlist();
    },
    windowScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      const scrollHeight = document.body.scrollHeight;
      const availableHeight = window.screen.availHeight;
      const distanceBottom = scrollHeight - scrollTop - availableHeight;
      if (distanceBottom < 150 && !this.spinning && this.hasNextPage) {
        this.page++;
        this.getNFTlist();
      }
    },
    async getNFTlist() {
      this.spinning = true;
      const params = {
        gallery_type: this.galleryType, // commercial  cc0
        paginate: {
          page: this.page,
          page_size: this.pageSize,
        },
        is_sort_by_recently: this.sortType === "time",
        sort_by_price_asc: this.sortType === "lowhigh",
        sort_by_price_desc: this.sortType === "highlow",
      };
      if (this.collection_checked) {
        params.collection_ids = [this.collection_checked];
      }
      if (this.page <= 1) {
        this.listData = [];
      }
      const { data, page_info } = await API.excute("getNFTlist", params);

      this.listData = [...this.listData, ...data];
      this.total = page_info.total;
      this.hasNextPage = page_info.has_next_page;
      this.spinning = false;
    },
    goDetail() {
      this.dialogVisible = true;
    },
    listMyNFT() {
      this.$refs.dialogForList.open();
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
