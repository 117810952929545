<template>
  <div>
    <div v-show="!show" id="customize">
      <div class="customize-wrap">
        <div class="main-container customize-main">
          <div class="design-title" v-show="canDesign">
            <p
              class="first-title"
              :class="{ 'first-title-color': open }"
              @click="back"
            >
              &lt; Customize /
            </p>
            <p class="second-title">
              Customize your
              {{ product[selectedCate].categoryText.toLowerCase() }}
            </p>
          </div>
          <div v-show="!canDesign">
            <p class="customize-title">CUSTOMIZE YOUR PRODUCT</p>
            <p class="customize-price">
              <img src="@/assets/images/customize/price.png" alt="价格icon" />
              {{ defaultPrice }}
            </p>
          </div>
          <div class="container" :style="{ 'min-height': `${cHeight}px` }">
            <div class="phone" :class="{ small: open }">
              <div v-show="canDesign" class="design-layout">
                <div class="design-left">
                  <div class="design-tab" v-show="!isOnly">
                    <span
                      :class="{ 'design-tab--active': isFrontActive }"
                      @click="changeFront"
                      >Front</span
                    >
                    <span
                      :class="{ 'design-tab--active': !isFrontActive }"
                      @click="changeBack"
                      >Back</span
                    >
                  </div>
                  <div
                    class="design-thumbnail"
                    :class="[{ 'design-only': isOnly }]"
                  >
                    <div
                      class="thumbnail-front"
                      :class="[
                        { 'thumbnail-active': isFrontActive },
                        { 'checkout-active': bgIsNone },
                      ]"
                      @click="changeFront"
                    >
                      <p v-show="!isOnly">front</p>
                      <div style="position: relative" id="minimapClothesFront">
                        <!-- 不可编辑 -->
                        <div
                          class="minimap-container"
                          :class="[
                            { 'minimap-only': isOnly },
                            { 'minimap-print': selectedCate === 4 },
                          ]"
                        >
                          <canvas
                            id="minimap"
                            :width="minimapWidth"
                            :height="minimapHeight"
                          ></canvas>
                        </div>
                        <div
                          class="print-container"
                          :class="{ 'print-layout': selectedCate === 4 }"
                          id="print-container"
                        >
                          <div v-show="selectedCate === 4" class="print-text">
                            <p
                              class="print-name"
                              :class="{ 'print-lessen': lessen }"
                            >
                              <span>{{ nftNameObj[0] }}</span>
                            </p>
                            <p class="print-desc print-token">
                              <span>
                                {{ nftNameObj[1] }}
                              </span>
                            </p>
                            <p class="print-desc print-contract">
                              {{ contractAddress }}
                            </p>
                            <p
                              class="print-desc print-date"
                              style="letter-spacing: -0.04em"
                            >
                              {{ data.year }}
                              <span>/</span>
                              {{ data.month }}
                              <span>/</span>
                              {{ data.date }}
                            </p>
                            <p class="print-desc print-address">
                              {{ walletAddress }}
                            </p>
                            <vue-qr
                              v-show="qrCodeShow"
                              class="print-qrcode"
                              :size="200"
                              :text="tagUrl"
                            ></vue-qr>
                          </div>
                          <img
                            :class="{ print: selectedCate === 4 }"
                            id="clothing-front"
                            :src="colorOption[0].src"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      v-show="!isOnly"
                      class="thumbnail-back"
                      :class="[
                        { 'thumbnail-active': !isFrontActive },
                        { 'checkout-active': bgIsNone },
                      ]"
                      @click="changeBack"
                    >
                      <p>back</p>
                      <div style="position: relative" id="minimapClothesBack">
                        <img
                          id="clothing-back"
                          v-if="!isOnly"
                          :src="colorOption[1].src"
                        />
                        <div class="minimapBack-container">
                          <canvas
                            id="minimapBack"
                            :width="minimapWidth"
                            :height="minimapHeight"
                          ></canvas>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div @click="exchange" class="design-exchange">Exchange</div>
                </div>
                <div class="design-right">
                  <div class="design-interaction">
                    <div class="interaction-icon" @click="enterMatting">
                      <img
                        src="@/assets/images/customize/matting.png"
                        alt="抠图Icon"
                      />
                    </div>
                    <a-modal
                      :width="canvasWidth"
                      :height="canvasHeight"
                      title=""
                      :bodyStyle="modalStyle"
                      v-model="modalVisible"
                      :maskClosable="false"
                      :closable="false"
                      :footer="false"
                      :class="{ 'progress-mask': progressShow }"
                    >
                      <div class="matting-title">Edit your NFT</div>
                      <div class="matting-layout">
                        <div class="matting-left">
                          <div
                            class="matting-container"
                            :style="{
                              width: canvasWidth.slice(0, -2) - 527.78 + 'px',
                              height: canvasHeight.slice(0, -2) - 184 + 'px',
                            }"
                          >
                            <canvas
                              id="matting"
                              :width="mattingWidth"
                              :height="mattingHeight"
                              crossOrigin="anonymous"
                            ></canvas>
                            <div
                              id="shape"
                              class="shape"
                              :style="{
                                width: drawRadius + 'px',
                                height: drawRadius + 'px',
                                'margin-left': -(drawRadius / 2) + 'px',
                                'margin-top': -(drawRadius / 2) + 'px',
                                'border-radius':
                                  drawShape === 'round' ? '50%' : 0,
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="matting-right">
                          <p class="matting-erasure">Operation</p>
                          <div class="btn-area matting-btn">
                            <a-button
                              class="add-cart-btn btn-transparent"
                              @click="undoMatting"
                              >undo</a-button
                            >
                            <!-- <a-button
                              class="add-cart-btn btn-transparent"
                              @click="setzoom(-0.1)"
                              >reduce</a-button
                            >
                            <a-button
                              class="add-cart-btn btn-transparent"
                              @click="setzoom(1)"
                              >init</a-button
                            >
                            <a-button
                              class="add-cart-btn btn-transparent"
                              @click="setzoom(0.1)"
                              >enlarge</a-button
                            > -->
                          </div>
                          <a-tabs
                            :activeKey="modalTabKey"
                            :tab-bar-style="{
                              'border-bottom': '1px solid #676767',
                            }"
                            @change="modalTabCallBack"
                          >
                            <a-tab-pane key="0" tab="Matting">
                              <div class="btn-area matting-btn">
                                <div style="width: 100%">
                                  <a-button
                                    class="add-cart-btn btn-transparent"
                                    @click="createStream(0)"
                                    >Auto</a-button
                                  >
                                  <span class="matting-desc"
                                    >One key matting</span
                                  >
                                </div>
                                <div></div>
                              </div>
                              <p class="matting-range">Customizing rage ：</p>

                              <div class="range-layout">
                                <div style="width: 90%">
                                  <a-slider
                                    v-model="range"
                                    :min="0"
                                    :max="100"
                                    :step="1"
                                    class="matting-range--slider"
                                  ></a-slider>
                                </div>
                                <p class="radius">{{ range }}</p>
                              </div>
                            </a-tab-pane>
                            <a-tab-pane key="1" tab="Ereaser">
                              <div class="matting-shape">
                                <span class="tab-title">Shape：</span>
                                <a-button
                                  v-for="(item, index) in options"
                                  :key="index"
                                  :class="{
                                    activeColor: index === selectedEreaserShape,
                                  }"
                                  @click="changeEreaserShape(index)"
                                ></a-button>
                              </div>
                              <div class="matting-size">
                                <span class="tab-title">Size：</span>
                                <div
                                  class="range-layout"
                                  style="width: 100%; align-items: center"
                                >
                                  <div style="width: 85%">
                                    <a-slider
                                      v-model="eraseRadius"
                                      @change="brushChange"
                                    ></a-slider>
                                  </div>
                                  <p class="radius" style="margin-bottom: 0">
                                    {{ eraseRadius }}
                                  </p>
                                </div>
                              </div>
                            </a-tab-pane>
                            <a-tab-pane key="2" tab="Recover">
                              <div class="matting-shape">
                                <span class="tab-title">Shape：</span>
                                <a-button
                                  v-for="(item, index) in options"
                                  :key="index"
                                  :class="{
                                    activeColor: index === selectedRecoverShape,
                                  }"
                                  @click="changeRecoverShape(index)"
                                ></a-button>
                              </div>
                              <div class="matting-size">
                                <span class="tab-title">Size：</span>

                                <div
                                  class="range-layout"
                                  style="width: 100%; align-items: center"
                                >
                                  <div style="width: 85%">
                                    <a-slider
                                      v-model="undoRadius"
                                      @change="brushChange"
                                    ></a-slider>
                                  </div>
                                  <p class="radius" style="margin-bottom: 0">
                                    {{ undoRadius }}
                                  </p>
                                </div>
                              </div>
                            </a-tab-pane>
                          </a-tabs>
                          <div class="btn-area matting-footer">
                            <a-button
                              class="add-cart-btn btn-back"
                              @click="hideModal"
                              >Cancel</a-button
                            >
                            <a-button
                              class="add-cart-btn btn-transparent"
                              @click="confirmModal"
                              >Save</a-button
                            >
                          </div>
                        </div>
                      </div>
                      <div
                        class="matting-progress"
                        :style="{ width: canvasWidth, height: canvasHeight }"
                        v-show="progressShow"
                      >
                        <a-progress :percent="mattingPercent" />
                      </div>
                    </a-modal>
                    <div class="interaction-icon" @click="flipX">
                      <img
                        src="@/assets/images/customize/fliplr.png"
                        alt="左右镜像翻转Icon"
                      />
                    </div>
                    <div class="interaction-icon" @click="flipY">
                      <img
                        src="@/assets/images/customize/fliptb.png"
                        alt="上下镜像翻转Icon"
                      />
                    </div>
                    <div v-show="selectedCate !== 4">
                      <div class="interaction-icon" @click="undo">
                        <img
                          src="@/assets/images/customize/undo.png"
                          alt="撤回Icon"
                        />
                      </div>
                      <div class="interaction-icon" @click="getText">
                        <img
                          src="@/assets/images/customize/text.png"
                          alt="文本Icon"
                        />
                      </div>
                      <div class="interaction-icon" @click="setTag">
                        <img :src="tagSrc" alt="tag Icon" />
                      </div>
                    </div>
                  </div>
                  <div class="tag-box" v-show="tagShow && selectedCate !== 4">
                    <div
                      class="tag-container"
                      :class="[
                        { 'tag-container--active': tagWhiteActive },
                        { 'tag-wait': !canSetTag },
                      ]"
                      @click="changeWhiteTag"
                    >
                      <div id="tagWhiteImage">
                        <img
                          src="@/assets/images/customize/tag-white.png"
                          alt="white tag"
                        />
                        <div
                          class="tag-title"
                          style="color: black"
                          :class="{ 'tag-lessen': lessen }"
                        >
                          <span>
                            {{ nftName }}
                          </span>
                        </div>
                        <div class="tag-text first text-first">
                          <p>{{ contractAddress }}</p>
                        </div>
                        <div class="tag-text first text-second">
                          <p style="letter-spacing: -0.04em">
                            {{ data.year }}
                            <span>/</span>
                            {{ data.month }}
                            <span>/</span>
                            {{ data.date }}
                          </p>
                        </div>
                        <div class="tag-text first text-third">
                          <p>{{ walletAddress }}</p>
                        </div>
                        <vue-qr
                          v-show="qrCodeShow"
                          class="tag-qrcode"
                          :size="200"
                          :text="tagUrl"
                        ></vue-qr>
                      </div>
                    </div>
                    <div
                      class="tag-container"
                      :class="[
                        { 'tag-container--active': tagBlackActive },
                        { 'tag-wait': !canSetTag },
                      ]"
                      @click="changeBlackTag"
                    >
                      <div id="tagBlackImage">
                        <img
                          src="@/assets/images/customize/tag-black.png"
                          alt="black tag"
                        />
                        <div
                          class="tag-title"
                          :class="{ 'tag-lessen': lessen }"
                        >
                          <span>
                            {{ nftName }}
                          </span>
                        </div>
                        <div class="tag-text text-first">
                          <p>{{ contractAddress }}</p>
                        </div>
                        <div class="tag-text text-second">
                          <p style="letter-spacing: -0.04em">
                            {{ data.year }}
                            <span>/</span>
                            {{ data.month }}
                            <span>/</span>
                            {{ data.date }}
                          </p>
                        </div>
                        <div class="tag-text text-third">
                          <p>{{ walletAddress }}</p>
                        </div>
                        <vue-qr
                          v-show="qrCodeShow"
                          class="tag-qrcode"
                          :size="200"
                          :text="tagUrl"
                        ></vue-qr>
                      </div>
                    </div>
                  </div>
                  <div
                    class="design-main"
                    :class="[
                      { 'exchange-active': isExchangeFront },
                      { 'exchange-only': isOnly },
                      { 'exchange-both': isOnly && isExchangeFront },
                      { 'exchange-print': selectedCate === 4 },
                      {
                        'exchange-print--both':
                          selectedCate === 4 && isExchangeFront,
                      },
                    ]"
                    id="design-main--front"
                    v-show="isFrontActive"
                  >
                    <div class="only" :class="{ print: selectedCate === 4 }">
                      <!-- 可编辑 -->
                      <canvas
                        id="design"
                        :width="width"
                        :height="height"
                      ></canvas>
                    </div>
                  </div>
                  <div
                    class="design-main"
                    :class="[{ 'exchange-active': isExchangeBack }]"
                    id="design-main--back"
                    v-show="!isFrontActive"
                  >
                    <canvas
                      id="designBack"
                      :width="width"
                      :height="height"
                    ></canvas>
                  </div>
                </div>
              </div>

              <div v-show="!canDesign" class="design-l">
                <div class="type-wrap">
                  <div
                    @click="selectType(index)"
                    class="type-i"
                    :class="{ 'type-i_selected': index === selectedCate }"
                    v-for="(item, index) in product"
                    :key="index"
                  >
                    <img
                      :class="[item.categoryClassName]"
                      :src="item.categoryImg"
                    />
                    <span class="type-text type-text_selected">{{
                      item.categoryText
                    }}</span>
                  </div>
                </div>
                <div
                  :class="{
                    'img-model-case':
                      product[selectedCate].categoryText === 'Case' ||
                      product[selectedCate].categoryText === 'Houseware' ||
                      product[selectedCate].categoryText === 'Accessory' ||
                      product[selectedCate].categoryText === 'Others',
                    'img-model-print':
                      product[selectedCate].categoryText === 'Painting',
                    'img-model-clothes':
                      product[selectedCate].categoryText === 'Clothes',
                  }"
                  style="overflow: hidden"
                >
                  <img
                    v-if="selectedProduct.cover_image"
                    :src="selectedProduct.cover_image"
                  />
                </div>
              </div>
            </div>
            <div class="right" :class="{ open: open }">
              <div v-if="canDesign" class="r r2">
                <div>
                  <p class="type-title">
                    CUSTOMIZE
                    {{ product[selectedCate].categoryText.toUpperCase() }}
                  </p>
                  <p class="design-price">
                    <img
                      src="@/assets/images/customize/price.png"
                      alt="价格icon"
                    />
                    {{ selectedProduct.price }}
                  </p>
                  <p class="type-littleTitle">Choose your device</p>
                  <div class="options-selection" style="margin-bottom: 20px">
                    <label class="title-label">Type：</label>
                    <div
                      style="padding-left: 80px"
                      v-if="goodsData.length > 0"
                      ref="selectBox"
                    >
                      <a-select
                        label-in-value
                        :default-value="selectVal"
                        v-model="selectVal"
                        placeholder="请选择"
                        :getPopupContainer="() => this.$refs.selectBox"
                        @change="selectChange($event)"
                      >
                        <a-select-option
                          v-for="item in goodsData"
                          :key="item.id"
                          :label="item.name"
                          >{{ item.name }}</a-select-option
                        >
                      </a-select>
                    </div>
                  </div>
                  <div class="options-selection">
                    <label class="title-label">Color：</label>
                    <div style="padding-left: 80px">
                      <a-button
                        v-for="(item, index) in selectedProduct.options"
                        :key="index"
                        v-show="
                          (selectedProduct.options.length <= 4 &&
                            index % 2 !== 0) ||
                          selectedProduct.options.length <= 2
                        "
                        :class="{
                          activeColor_b: item.name === 'black',
                          activeColor_w: item.name === 'white',
                          activeColor: index === selectedColor,
                        }"
                        @click="changeBg(item, index)"
                      ></a-button>
                    </div>
                  </div>
                  <div
                    class="options-selection"
                    v-if="remarksOption.length > 0"
                    style="margin-top: 20px"
                  >
                    <label class="title-label">Size：</label>
                    <div style="padding-left: 80px">
                      <span
                        class="size-selection"
                        v-for="(item, index) in remarksOption"
                        :key="index"
                        :class="{ activeSizeColor: index === selectedSize }"
                        @click="changeSize(item, index)"
                        >{{ item }}</span
                      >
                    </div>
                  </div>
                </div>

                <div>
                  <p style="margin-bottom: 0" class="type-title mt_50">
                    Choose NFT
                  </p>
                  <a-tabs
                    default-active-key="1"
                    :tab-bar-style="{
                      'border-bottom': '1px solid #676767',
                    }"
                    @change="tabCallBack"
                  >
                    <a-tab-pane key="1" tab="Your Wallet">
                      <div
                        class="image-wrapper scroll-height"
                        @scroll="onScroll"
                        ref="scrollRef"
                      >
                        <div
                          @click="chooseNFT(item, index, info.price)"
                          :key="index"
                          v-for="(item, index) in info"
                          class="nftItem"
                        >
                          <img
                            class="nft-img"
                            :class="{ isActive: selectedNFT === index }"
                            :src="item.image"
                          />
                        </div>
                      </div>
                      <div class="loading-box" v-show="isLoadingNFT">
                        <a-spin
                          class="ant-spinning"
                          :spinning="isLoadingNFT"
                        ></a-spin>
                      </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="CC0">
                      <NFTList type="cc0" @handleChoose="chooseNFT" />
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="Commercial">
                      <NFTList type="commercial" @handleChoose="chooseNFT" />
                    </a-tab-pane>
                  </a-tabs>
                </div>

                <!-- <div>
                  <p class="type-title title-margin">
                    Type the owner (optional)
                  </p>
                  <a-input
                    placeholder="请输入内容"
                    v-model="optionTxt"
                    @blur="onTextChange"
                  />
                </div> -->

                <div class="btn-area right-footer">
                  <!-- <div class="disconnect-btn">Disconnect</div> -->
                  <div @click="back" class="add-cart-btn btn-back">Go Back</div>
                  <div @click="goCheck" class="add-cart-btn btn-transparent">
                    Check Out
                  </div>
                </div>
              </div>

              <div v-else class="r r1">
                <div class="design-r">
                  <div>
                    <p class="design-connect-title">
                      Connect wallet and customize
                    </p>
                    <p
                      v-if="address"
                      @click="goDesign"
                      class="design-connect-btn"
                    >
                      Start Customizing
                    </p>
                    <p v-else @click="goConnect" class="design-connect-btn">
                      Connect
                    </p>
                  </div>
                  <div
                    style="margin: 42px 0"
                    v-if="goodsData.length > 0"
                    ref="selectBox"
                  ></div>
                  <div
                    v-if="selectedProduct.description"
                    class="design-desc"
                    :class="{
                      'clothes-feature':
                        product[selectedCate].categoryText === 'clothes',
                    }"
                    v-html="
                      selectedProduct.description.replace(/&nbsp;/gi, ' ')
                    "
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loading
        :tip="'loading...'"
        :spinning="spinning"
        :styleProps="{
          background: 'rgba(0, 0, 0, 0.3)',
          'backdrop-filter': 'blur(0)',
        }"
      />
    </div>
    <Check
      v-show="show"
      ref="checkRef"
      @back="goBack"
      @backToCustomize="goBackTwo"
    >
    </Check>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import { wallet } from "@u/wallet";

import { walletWeb3modal } from "@u/wallet_web3modal";
import { mapMutations, mapState } from "vuex";

import find from "lodash/find";
import { fabric } from "fabric-with-erasing";
import html2canvas from "html2canvas";
// import reject from "lodash/reject";
// import capitalize from "lodash/capitalize";
import * as API from "@api/request";
// import { convertBase64UrlToBlob } from "@/utils/tools";
// import web3Modal from "@u/web3Modal";
import { dataUrlToCdn } from "@u/dataUrlToCdn";
import { convertUrlToBase64 } from "@u/convertUrlToBase64";
import { textToImage } from "@u/textToImage";
import { imgListToCdn } from "@u/dataUrlToCdn";
import { product } from "../../config/product";
import NFTList from "./components/nftList.vue";
const tagImage = [
  require("@/assets/images/customize/tag.png"),
  require("@/assets/images/customize/tag-active.png"),
];
const tagOptions = {
  backgroundColor: null,
  useCORS: true, // 开启跨域配置
  scale: 16, //按比例增加分辨率 (2=双倍).
  dpi: window.devicePixelRatio * 16, //设备像素比
};
export default {
  name: "Customize",
  components: {
    NFTList,
    VueQr,
    Loading: () => import("@/components/Loading.vue"),
    Check: () => import("./check.vue"),
  },
  data() {
    return {
      tagSrc: tagImage[0],
      tagId: 0,
      // tagPrice: "0",
      nftName: "Loading...",
      nftNameObj: ["", ""],
      contractAddress: "Loading...",
      walletAddress: "Loading",
      data: {
        year: "",
        month: "",
        date: "",
      },
      tagShow: false,
      tagWhiteActive: false,
      tagBlackActive: false,
      tagUrl: "",
      canSetTag: true,
      lessen: true,
      qrCodeShow: true,
      mattingId: 0,
      mattingIndex: 0,
      defaultPrice: 0,
      design: "",
      designBack: "",
      minimap: "",
      minimapBack: "",
      modalVisible: false,
      bgIsNone: false,
      mattingImageSrc: "",
      canvas: "",
      range: 20,
      mattingPercent: 0,
      mattingBg: "",
      progressShow: false,
      isEnter: false,
      undoRadius: 10,
      eraseRadius: 10,
      drawRadius: 0,
      drawShape: "round",
      status: {
        select: true,
        erase: false,
        undo: false,
      },
      mouseX: "",
      mouseY: "",
      // canvasZoom: 1,
      selectedEreaserShape: 0,
      selectedRecoverShape: 0,
      options: [
        {
          value: "round",
          label: "圆形",
        },
        {
          value: "square",
          label: "正方形",
        },
      ],
      colorOption: [
        {
          src: "",
        },
        {
          src: "",
        },
      ],
      config: {
        canvasState: [],
        currentStateIndex: -1,
        undoStatus: false, // 重做状态
        undoFinishedStatus: 1,
        undoButton: this.$refs.undo, //得不到  在 mounted 得到
      },
      configBack: {
        canvasState: [],
        currentStateIndex: -1,
        undoStatus: false, // 重做状态
        undoFinishedStatus: 1,
        undoButton: this.$refs.undo, //得不到  在 mounted 得到
      },
      matting: {
        canvasState: [],
      },
      modalStyle: {
        background: "rgba(47, 47, 47)",
        "border-radius": "15px",
        padding: "67.5px 43px",
      },
      minimapWidth: 80.3,
      minimapHeight: 104, //  除以6
      width: 492, // canvas 宽度  todo: deep design * 2
      height: 624, // canvas 高度 todo: deep design * 2
      designWidth: 492,
      designHeight: 624,
      mattingWidth: 512,
      mattingHeight: 512,
      nftWidth: 0,
      nftHeight: 0,
      canvasWidth: "1050px",
      canvasHeight: "690px",

      // cHeight: window.screen.height,
      cHeight: 1196,
      canDesign: false,
      open: false,
      selectVal: null,
      // selectVal: "iphone 13 pro",
      info: [],
      product,
      selectedCate: 0, // 选中下标case, clothes...
      selectedNFT: -1, // 选中下标NFT
      optionTxt: "",
      goodsData: [],
      selectedProduct: {},
      tabKey: 1,
      modalTabKey: 1,
      selectedColor: 0,
      selectedShape: 0,
      startCursor: 0,
      hasNext: false,
      hide: false,
      spinning: false,
      selectedSize: 0,
      nftIds: [],
      isLoadingNFT: false,
      texts: [],
      tagInfo: [],
      show: false,
      isFrontActive: true,
      isExchangeFront: false,
      isExchangeBack: false,
      isBlack: false,
      isOnly: true,
      designBackIsFrist: false,
      designIsFrist: false,
      onlyBg: {
        white: [
          {
            src: "",
          },
        ],
        black: [
          {
            src: "",
          },
        ],
      },
      notOnlyBg: {
        white: [
          {
            src: "",
          },
          {
            src: "",
          },
        ],
        black: [
          {
            src: "",
          },
          {
            src: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    address() {
      return this.userInfo?.wallet_address;
    },
  },
  methods: {
    ...mapMutations({
      saveOrder: "order/SAVE_ORDER",
    }),
    async htmlToCanvas(dom, options = {}) {
      let img;
      await html2canvas(dom, options).then((canvas) => {
        img = canvas.toDataURL("image/png");
      });
      return img;
    },
    getData() {
      let nowDate = new Date();
      let month = nowDate.getMonth() + 1;
      let date = {
        year: nowDate.getFullYear(),
        month: month < 10 ? "0" + month : month,
        date:
          nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate(),
      };
      this.data.month = date.month;
      this.data.date = date.date;
      this.data.year = date.year;
    },
    async changeWhiteTag() {
      if (!this.canSetTag) {
        this.$message.info("Tag is being generated, please try again later!");
        return;
      }
      let img = await this.htmlToCanvas(
        document.getElementById("tagWhiteImage"),
        tagOptions
      );
      this.tagWhiteActive = true;
      this.tagBlackActive = false;
      this.addTag(img);
    },
    async changeBlackTag() {
      if (!this.canSetTag) {
        this.$message.info("Tag is being generated, please try again later!");
        return;
      }
      let img = await this.htmlToCanvas(
        document.getElementById("tagBlackImage"),
        tagOptions
      );
      this.tagWhiteActive = false;
      this.tagBlackActive = true;
      this.addTag(img);
    },
    async getImg() {
      let a1, a2, clothesFront, clothesBack;
      const ext = "png";
      const options = {
        backgroundColor: null, // null或transparent可将canvas背景设置为透明
      };
      if (this.selectedCate === 4) {
        a1 = await this.htmlToCanvas(
          document.getElementById("design-main--front"),
          options
        );
      } else if (this.isOnly) {
        a1 = await this.htmlToCanvas(
          document.getElementsByClassName("design")[0],
          options
        );
      } else {
        a1 = await this.design.toDataURL({
          format: ext,
          enableRetinaScaling: true,
        });
      }
      if (this.isExchangeFront) {
        this.setExchangeFront();
      }

      clothesFront = await this.htmlToCanvas(
        document.getElementsByClassName("thumbnail-front")[0]
      );

      if (this.isOnly) {
        let data = await imgListToCdn([a1, clothesFront]);
        return data;
      } else {
        a2 = await this.designBack.toDataURL({
          format: ext,
          enableRetinaScaling: true,
        });
        if (this.isExchangeBack) {
          this.setExchangeBack();
        }
        clothesBack = await this.htmlToCanvas(
          document.getElementsByClassName("thumbnail-back")[0]
        );
        let data = await imgListToCdn([a1, clothesFront, a2, clothesBack]);
        return data;
      }
    },
    handleStatusFalse() {
      for (let key in this.status) {
        this.status[key] = false;
      }
    },
    changeEreaserShape(index) {
      this.selectedEreaserShape = index;
      this.canvas.freeDrawingBrush.strokeLineCap = this.options[index].value;
      this.drawShape = this.options[index].value;
    },
    changeRecoverShape(index) {
      this.selectedRecoverShape = index;
      this.canvas.freeDrawingBrush.strokeLineCap = this.options[index].value;
      this.drawShape = this.options[index].value;
    },
    brushChange() {
      if (this.status.erase) {
        this.canvas.freeDrawingBrush.width = this.eraseRadius;
        this.drawRadius = this.eraseRadius;
      } else if (this.status.undo) {
        this.canvas.freeDrawingBrush.width = this.undoRadius;
        this.drawRadius = this.undoRadius;
      } else if (this.status.draw) {
        this.canvas.freeDrawingBrush.width = this.drawRadius;
      }
    },
    goBack() {
      this.bgIsNone = false;
      this.show = false;
    },
    goBackTwo() {
      this.goBack();
      this.back();
    },
    changeBack() {
      this.isFrontActive = false;
    },
    changeFront() {
      this.isFrontActive = true;
    },
    exchange() {
      if (this.isFrontActive) {
        if (!this.isExchangeFront) {
          this.backExchangeFront();
        } else {
          this.setExchangeFront();
        }
      } else {
        if (!this.isExchangeBack) {
          let back = document.getElementById("clothing-back");
          document.getElementById("design-main--back").appendChild(back);
          this.isExchangeBack = true;
        } else {
          this.setExchangeBack();
        }
      }
    },
    backExchangeFront() {
      this.isExchangeFront = true;
      if (this.isOnly) {
        this.minimap.set("backgroundColor", "white");
        this.minimap.renderAll();
      }
      let front = document.getElementById("print-container");
      document.getElementById("design-main--front").appendChild(front);
    },
    setExchangeFront() {
      if (this.isOnly) {
        this.minimap.set("backgroundColor", "transparent");
        this.minimap.renderAll();
      }
      let front = document.getElementById("print-container");
      document.getElementById("minimapClothesFront").appendChild(front);
      this.isExchangeFront = false;
    },
    setExchangeBack() {
      let back = document.getElementById("clothing-back");
      document.getElementById("minimapClothesBack").appendChild(back);
      this.isExchangeBack = false;
    },
    enterMatting() {
      if (this.canvas !== "") {
        this.canvas.clear();
      }
      if (this.isFrontActive) {
        if (
          this.design._activeObject === undefined ||
          this.design._activeObject === null ||
          typeof this.design._activeObject?.text === "string" ||
          this.design._activeObject.tagId !== undefined
        ) {
          this.$message.info("Please select the matting picture!");
          return;
        }
      } else {
        if (
          this.designBack._activeObject === undefined ||
          this.designBack._activeObject === null ||
          typeof this.designBack._activeObject?.text === "string" ||
          this.designBack._activeObject.tagId !== undefined
        ) {
          this.$message.info("Please select the matting picture!");
          return;
        }
      }
      this.modalTabKey = 0;
      this.modalVisible = true;
      setTimeout(() => {
        let item;
        if (this.isFrontActive) {
          item = this.design;
        } else {
          item = this.designBack;
        }
        this.mattingImageSrc = item._activeObject._element.currentSrc;
        this.mattingId = item._activeObject.id;
        this.mattingIndex = item._activeObject.index;
        this.nftWidth = item._activeObject.width;
        this.nftHeight = item._activeObject.height;

        this.changepic();
        this.modalTabCallBack("0");
      }, 700);
    },
    changepic() {
      const that = this;
      let image = new Image();
      if (this.canvas === "") {
        this.canvas = new fabric.Canvas("matting");
        this.canvas.setOverlayColor("rgba(255,0,255,0)", undefined, {
          erasable: false,
        });
        this.canvas.freeDrawingCursor = "none";
        image.crossOrigin = "anonymous";
        image.className = "image";
        this.canvas.on("mouse:up", function () {
          if (that.status.erase || that.status.undo) {
            that.saveMattingStatus();
            that.mattingBg = that.getOperationImage();
            that.canvas.renderAll();
          }
        });
        this.canvas.on("mouse:move", function (opt) {
          that.handleDrawAnimation(opt, that);
        });
        this.canvas.on("mouse:down", function (opt) {
          if (that.status.select) {
            let evt = opt.e;
            that.mouseX = evt.offsetX;
            that.mouseY = evt.offsetY;
            that.createStream(1);
          }
          that.handleDrawAnimation(opt, that);
        });
        // this.canvas.on("mouse:wheel", function (opt) {
        //   var delta = opt.e.deltaY;
        //   var zoom = that.canvas.getZoom();
        //   zoom *= 0.999 ** delta;
        //   that.canvasZoom = zoom;
        //   if (zoom > 20) zoom = 20;
        //   if (zoom < 0.01) zoom = 0.01;
        //   // that.canvas.zoomToPoint(
        //   //   {
        //   //     // 关键点
        //   //     x: opt.e.offsetX,
        //   //     y: opt.e.offsetY,
        //   //   },
        //   //   zoom // 传入修改后的缩放级别
        //   // );
        //   this.setZoom(zoom);
        //   opt.e.preventDefault();
        //   opt.e.stopPropagation();
        // });
        this.canvas.centeredScaling = true;
      }
      image.src = this.mattingImageSrc;
      fabric.Image.fromURL(
        image.src,
        (img) => {
          that.canvas.setWidth(img.width);
          that.canvas.setHeight(img.height);
          that.canvasWidth = img.width + 527.78 + "px";
          that.canvasHeight = img.height + 184 + "px";
          that.canvas.setBackgroundImage(
            img,
            that.canvas.renderAll.bind(that.canvas)
          );
        },
        { crossOrigin: "Anonymous" }
      );
      this.canvas.renderAll();
    },
    handleDrawAnimation(opt, that) {
      let shape = document.getElementById("shape");
      if (that.status.erase || that.status.undo) {
        let ofLeft = opt.absolutePointer.x;
        let ofTop = opt.absolutePointer.y;
        if (ofLeft < 0) {
          ofLeft = 0;
        }
        if (ofTop < 0) {
          ofTop = 0;
        }
        shape.style.display = "block";
        shape.style.left = ofLeft + "px";
        shape.style.top = ofTop + "px";
      } else {
        shape.style.display = "none";
      }
    },
    createStream(type) {
      this.saveMattingStatus();
      this.mattingPercent = 0;
      this.progressShow = true;
      let img = new Image();
      img.src = this.mattingImageSrc;
      img.crossOrigin = "";
      const that = this;
      img.onload = function () {
        that.mattingPercent = 10;
        if (type === 0) {
          that.pureMatting(img, img.width, img.height);
        } else {
          that.regionalCutout(img, img.width, img.height);
        }
      };
    },
    regionalCutout(img, width, height) {
      let c = document.getElementById("matting");
      c.width = width;
      c.height = height;
      let ctx = c.getContext("2d");
      img.width = width;
      img.height = height;
      this.mattingPercent = 20;
      ctx.drawImage(img, 0, 0);
      let tl = Array.prototype.slice
        .call(ctx.getImageData(0, 0, 1, 1).data)
        .join(",");
      let tr = Array.prototype.slice
        .call(ctx.getImageData(width - 1, 0, 1, 1).data)
        .join(",");
      let br = Array.prototype.slice
        .call(ctx.getImageData(width - 1, height - 1, 1, 1).data)
        .join(",");
      let bl = Array.prototype.slice
        .call(ctx.getImageData(0, height - 1, 1, 1).data)
        .join(",");
      let imgdata = [tl, tr, bl, br]; // 四个取色点
      this.mattingPercent = 30;
      let selfImageData = []; // 当前rgba
      imgdata.sort(); // 目前只支持纯色背景抠图，简单的判断是否为纯色 // let deferNum = this.unique(imgdata).length; // if (deferNum <= 1) {
      if (this.mouseX !== "" && this.mouseY !== "") {
        let data = ctx.getImageData(
          // Math.round(this.mouseX / this.canvasZoom),
          // Math.round(this.mouseX / this.canvasZoom),
          this.mouseX,
          this.mouseY,
          1,
          1
        ).data;
        selfImageData = Array.prototype.slice.call(data);
        selfImageData =
          selfImageData[0] +
          "," +
          selfImageData[1] +
          "," +
          selfImageData[2] +
          "," +
          selfImageData[3];
        selfImageData = (selfImageData + "").split(",");
      } else {
        selfImageData = imgdata[1].split(","); // 设置要扣除的主题色
      }
      let isPNG = true; // 判断是否已经扣过
      try {
        let imgDataUrl = ctx.getImageData(0, 0, width, height); //获取像素点
        let pixiArr = imgDataUrl.data;
        let stack = [];
        let x = this.mouseX,
          y = this.mouseY; // 8个方向

        let dx = [0, 1, 1, 1, 0, -1, -1, -1];
        let dy = [-1, -1, 0, 1, 1, 1, 0, -1];

        let map = {}; // 标识已经处理过的像素点，防止重复处理 // 如果开始像素符合条件，则将它放入栈中并标识为已处理
        let cell = (x + y * c.width) * 4;
        if (
          this.testColor(
            [pixiArr[cell], pixiArr[cell + 1], pixiArr[cell + 2]],
            selfImageData
          )
        ) {
          let firstPixi = `x${x}y${y}`; // `x${x}y${y}`是一个不会重复的唯一标识id
          map[firstPixi] = true;
          stack.push({
            x,
            y,
          });
        } else return;
        // 否则直接结束;
        this.mattingPercent = 40;
        let p; // position;
        while ((p = stack.pop())) {
          // 获取栈顶待处理的符合条件的像素的x与y值;
          cell = (p.x + p.y * c.width) * 4;
          pixiArr[cell + 3] = 0;
          // 测试周围8个是否符合抠除条件;
          for (let i = 0; i < 8; i++) {
            let nx = p.x + dx[i];
            let ny = p.y + dy[i];
            //是否在范围内并且没有被处理过;
            if (
              nx >= 0 &&
              nx < c.width &&
              ny >= 0 &&
              ny < c.height &&
              !map[`x${nx}y${ny}`]
            ) {
              cell = (nx + ny * c.width) * 4;
              if (
                this.testColor(
                  [pixiArr[cell], pixiArr[cell + 1], pixiArr[cell + 2]],
                  selfImageData
                )
              ) {
                map[`x${nx}y${ny}`] = true;
                // 标识此像素已被处理;
                // 没处理过则放入栈中;
                stack.push({
                  x: nx,
                  y: ny,
                });
              }
            }
          }
        }
        this.mattingPercent = 90;
        ctx.putImageData(imgDataUrl, 0, 0);
        this.saveImage(c);
        if (isPNG) {
          this.mattingPercent = 100;
        } else {
          alert("背景早已抠除！");
        }
      } catch (e) {
        alert(e);
      }
    },
    async pureMatting(img, width, height) {
      let c = document.getElementById("matting");
      c.width = width;
      c.height = height;
      this.mattingPercent = 20;
      let ctx = c.getContext("2d");
      ctx.drawImage(img, 0, 0);
      let tl = Array.prototype.slice
        .call(ctx.getImageData(0, 0, 1, 1).data)
        .join(",");
      let tr = Array.prototype.slice
        .call(ctx.getImageData(width - 1, 0, 1, 1).data)
        .join(",");
      let br = Array.prototype.slice
        .call(ctx.getImageData(width - 1, height - 1, 1, 1).data)
        .join(",");
      let bl = Array.prototype.slice
        .call(ctx.getImageData(0, height - 1, 1, 1).data)
        .join(",");
      let imgdata = [tl, tr, bl, br]; // 四个取色点
      this.mattingPercent = 30;
      let selfImageData = []; // 当前rgba
      imgdata.sort();
      selfImageData = imgdata[1].split(","); // 设置要扣除的主题色
      let isPNG = true; // 判断是否已经扣过
      this.mattingPercent = 40;
      try {
        let imgDataUrl = ctx.getImageData(0, 0, width, height); //获取像素点
        let data = Array.prototype.slice.call(imgDataUrl.data);
        const threePartIndex = Math.floor(data.length / 4);
        let fourPart = data.splice(-threePartIndex);
        let thirdPart = data.splice(-threePartIndex);
        let secondPart = data.splice(-threePartIndex);
        let firstPart = data;
        let newArr = [firstPart, secondPart, thirdPart, fourPart];
        const that = this;
        const asyncTask = (taskID) => {
          for (let i = 0; i < newArr[taskID].length; i += 4) {
            // 得到 RGBA 通道的值
            let r = newArr[taskID][i];
            let g = newArr[taskID][i + 1];
            let b = newArr[taskID][i + 2];
            if (that.testColor([r, g, b], selfImageData)) {
              newArr[taskID][i + 3] = 0;
            }
          }
          // 异步成功执行
          return taskID;
        };
        let p1 = new Promise(() => {
          asyncTask(0);
        });
        let p2 = new Promise(() => {
          asyncTask(1);
        });
        let p3 = new Promise(() => {
          asyncTask(2);
        });
        let p0 = new Promise(() => {
          asyncTask(3);
        });
        Promise.all([p2, p1, p0, p3]);
        let a = new Uint8ClampedArray(newArr.flat());
        let s = new ImageData(a, width, height);
        this.mattingPercent = 90;
        ctx.putImageData(s, 0, 0);
        this.saveImage(c);
        if (isPNG) {
          this.mattingPercent = 100;
        } else {
          alert("背景早已抠除！");
        }
      } catch (e) {
        alert(e);
      }
    },
    testColor(current, target) {
      for (let i = 0; i < 3; i++) {
        if (
          !(
            (1 - this.range / 100) * target[i] <= current[i] &&
            (1 + this.range / 100) * target[i] >= current[i]
          )
        ) {
          return false;
        }
      }
      return true;
    },
    saveImage(c) {
      let url = c.toDataURL("image/png");
      this.stretchImage(url, this.canvas);
    },
    stretchImage(url, canvas) {
      fabric.Image.fromURL(url, (img) => {
        let a = canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
          scaleX: canvas.width / img.width, // 计算出图片要拉伸的宽度
          scaleY: canvas.height / img.height, // 计算出图片要拉伸的高度
        });
        if (a) {
          this.mattingBg = a.backgroundImage._element.currentSrc;
          this.progressShow = false;
        }
      });
    },
    // setzoom(val) {
    //   let zoom = this.canvas.getZoom(); // 获取画布当前缩放级别
    //   if (val === 1) {
    //     this.canvas.setZoom(1);
    //     return;
    //   }
    //   if (zoom > 20) {
    //     zoom = 20;
    //   } else if (zoom < 0.01) {
    //     zoom = 0.01;
    //   } else {
    //     zoom += val;
    //   }
    //   this.canvas.setZoom(zoom); // 设置画布缩放级别
    // },
    confirmModal() {
      const ext = "png";
      let base64;
      base64 = this.canvas.toDataURL({
        multiplier: 1,
        format: ext,
        enableRetinaScaling: false,
      });
      if (this.isFrontActive) {
        let img = this.design.getActiveObject();
        img.id = this.mattingId;
        img.index = this.mattingIndex;
        img.setSrc(base64, () => {
          this.design.renderAll();
          this.updateMiniMap();
        });
      } else {
        let img = this.designBack.getActiveObject();
        img.id = this.mattingId;
        img.index = this.mattingIndex;
        img.setSrc(base64, () => {
          this.designBack.renderAll();
          this.updateMiniMapBack();
        });
      }
      this.handleClose();
      this.modalVisible = false;
      this.isEnter = false;
    },
    hideModal() {
      this.canvas.backgroundImage = "";
      this.handleClose();
      this.modalVisible = false;
      this.isEnter = false;
    },
    handleClose() {
      this.matting.canvasState = [];
      this.mattingBg = "";
    },
    updateCanvasState() {
      let config = this.config;
      var jsonData = this.design.toJSON();
      var canvasAsJson = JSON.stringify(jsonData);

      if (config.currentStateIndex < config.canvasState.length - 1) {
        var indexToBeInserted = config.currentStateIndex + 1;
        config.canvasState[indexToBeInserted] = canvasAsJson;
        var numberOfElementsToRetain = indexToBeInserted + 1;
        config.canvasState = config.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        config.canvasState.push(canvasAsJson);
      }

      config.currentStateIndex = config.canvasState.length - 1;
      if (
        config.currentStateIndex == config.canvasState.length - 1 &&
        config.currentStateIndex > 0
      ) {
        config.undoStatus = true;
      }
    },
    updateCanvasStateBack() {
      let config = this.configBack;
      var jsonData = this.designBack.toJSON();
      var canvasAsJson = JSON.stringify(jsonData);

      if (config.currentStateIndex < config.canvasState.length - 1) {
        var indexToBeInserted = config.currentStateIndex + 1;
        config.canvasState[indexToBeInserted] = canvasAsJson;
        var numberOfElementsToRetain = indexToBeInserted + 1;
        config.canvasState = config.canvasState.splice(
          0,
          numberOfElementsToRetain
        );
      } else {
        config.canvasState.push(canvasAsJson);
      }

      config.currentStateIndex = config.canvasState.length - 1;
      if (
        config.currentStateIndex == config.canvasState.length - 1 &&
        config.currentStateIndex > 0
      ) {
        config.undoStatus = true;
      }
    },
    undo() {
      let _self = this;
      if (this.isFrontActive) {
        if (this.config.undoFinishedStatus) {
          if (this.config.currentStateIndex == -1) {
            this.config.undoStatus = false;
          } else {
            if (this.config.canvasState.length >= 1) {
              this.config.undoFinishedStatus = 0;
              if (this.config.currentStateIndex != 0) {
                this.config.undoStatus = true;
                _self.design.loadFromJSON(
                  this.config.canvasState[this.config.currentStateIndex - 1],
                  function () {
                    _self.design.renderAll();
                    _self.config.undoStatus = false;
                    _self.config.currentStateIndex -= 1;
                    // _self.config.undoButton.removeAttribute("disabled");
                    // _self.config.undoButton.disabled = false;
                    if (
                      _self.config.currentStateIndex !==
                      _self.config.canvasState.length - 1
                    ) {
                      // _self.config.redoButton.removeAttribute('disabled');
                      // _self.config.redoButton.disabled = false;
                    }
                    _self.config.undoFinishedStatus = 1;
                  }
                );
              } else if (_self.config.currentStateIndex == 0) {
                _self.design.clear();
                _self.config.undoFinishedStatus = 1;
                // _self.config.undoButton.disabled= "disabled";
                // _self.config.redoButton.removeAttribute('disabled');
                // _self.config.redoButton.disabled = false;
                _self.config.currentStateIndex -= 1;
              }
            }
          }
        }
      } else {
        if (this.configBack.undoFinishedStatus) {
          if (this.configBack.currentStateIndex == -1) {
            this.configBack.undoStatus = false;
          } else {
            if (this.configBack.canvasState.length >= 1) {
              this.configBack.undoFinishedStatus = 0;
              if (this.configBack.currentStateIndex != 0) {
                this.configBack.undoStatus = true;
                _self.designBack.loadFromJSON(
                  this.configBack.canvasState[
                    this.configBack.currentStateIndex - 1
                  ],
                  function () {
                    _self.designBack.renderAll();
                    _self.configBack.undoStatus = false;
                    _self.configBack.currentStateIndex -= 1;
                    // _self.configBack.undoButton.removeAttribute("disabled");
                    // _self.configBack.undoButton.disabled = false;
                    if (
                      _self.configBack.currentStateIndex !==
                      _self.configBack.canvasState.length - 1
                    ) {
                      // _self.configBack.redoButton.removeAttribute('disabled');
                      // _self.configBack.redoButton.disabled = false;
                    }
                    _self.configBack.undoFinishedStatus = 1;
                  }
                );
              } else if (_self.this.configBack.currentStateIndex == 0) {
                _self.designBack.clear();
                _self.configBack.undoFinishedStatus = 1;
                // _self.configBack.redoButton.removeAttribute('disabled');
                // _self.configBack.redoButton.disabled = false;
                _self.configBack.currentStateIndex -= 1;
              }
            }
          }
        }
      }
    },
    saveMattingStatus() {
      if (this.mattingBg !== "") {
        this.mattingImageSrc = this.mattingBg;
      }
      this.matting.canvasState.push(this.mattingImageSrc);
    },
    undoMatting() {
      const that = this;
      let a = that.matting.canvasState.pop();
      if (a === undefined) {
        this.$message.info("It's back to the end!");
        return;
      }
      this.mattingPercent = 0;
      this.progressShow = true;
      if (this.mattingBg !== "") {
        this.mattingImageSrc = a;
        this.mattingBg = a;
      }
      this.mattingPercent = 40;
      fabric.Image.fromURL(
        a,
        (img) => {
          that.canvas.setBackgroundImage(
            img,
            that.canvas.renderAll.bind(that.canvas),
            {
              scaleX: that.canvas.width / img.width, // 计算出图片要拉伸的宽度
              scaleY: that.canvas.height / img.height, // 计算出图片要拉伸的高度
            }
          );
          that.mattingPercent = 100;
          setTimeout(() => {
            that.progressShow = false;
          }, 100);
        },
        { crossOrigin: "Anonymous" }
      );
    },
    flipX() {
      if (this.isFrontActive) {
        this.design._activeObject.set(
          "flipX",
          !this.design._activeObject.flipX
        );
        this.design.renderAll();
        this.updateMiniMap();
      } else {
        this.designBack._activeObject.set(
          "flipX",
          !this.designBack._activeObject.flipX
        );
        this.designBack.renderAll();
        this.updateMiniMapBack();
      }
    },
    flipY() {
      if (this.isFrontActive) {
        let select = this.design.getActiveObject();
        select.flipY = !this.design._activeObject.flipY;
        this.design.renderAll();
        this.updateMiniMap();
      } else {
        let select = this.designBack.getActiveObject();
        select.flipY = !this.designBack._activeObject.flipY;
        this.designBack.renderAll();
        this.updateMiniMapBack();
      }
    },
    getText() {
      let itext;
      if (this.isBlack) {
        itext = new fabric.IText("This is a test text", {
          left: 100,
          top: 150,
          fill: "white",
          fontFamily: "Montserrat",
        });
      } else {
        itext = new fabric.IText("This is a test text", {
          left: 100,
          top: 150,
          fill: "black",
          fontFamily: "Montserrat",
        });
      }
      if (this.isFrontActive) {
        this.design.add(itext);
        this.design.renderAll();
        this.updateMiniMap();
      } else {
        this.designBack.add(itext);
        this.designBack.renderAll();
        this.updateMiniMapBack();
      }
    },
    setTag() {
      if (this.isFrontActive) {
        if (
          this.design._activeObject === undefined ||
          this.design._activeObject === null ||
          typeof this.design._activeObject?.text === "string"
        ) {
          this.$message.info("Please select the nft image!");
          return;
        }
      } else {
        if (
          this.designBack._activeObject === undefined ||
          this.designBack._activeObject === null ||
          typeof this.designBack._activeObject?.text === "string"
        ) {
          this.$message.info("Please select the nft image!");
          return;
        }
      }
      this.tagShow = !this.tagShow;
      if (this.tagShow) {
        this.tagSrc = tagImage[1];
      } else {
        this.tagSrc = tagImage[0];
      }
    },
    addTag(img) {
      const that = this;
      if (this.isFrontActive) {
        var imageUrl = new Image();
        imageUrl.onload = function () {
          var canvasImage = new fabric.Image(imageUrl);
          canvasImage.crossOrigin = "Anonymous"; //这里是主要添加的属性
          canvasImage.tagId = that.tagId;
          // canvasImage.setPrice = that.tagPrice;
          let scaleData, positionData;
          if (that.isOnly) {
            scaleData = (400 - 90) / canvasImage.width;
            positionData = {
              left: 45,
              top: 250,
            };
          } else {
            scaleData = (492 - 90) / canvasImage.width;
            positionData = {
              left: 45,
              top: 120,
            };
          }
          canvasImage.set(positionData).scale(scaleData);
          that.design.add(canvasImage);
          that.design._activeObject = canvasImage;
          that.design.renderAll();
        };
        imageUrl.crossOrigin = "Anonymous";
        imageUrl.src = img;
      } else {
        var imageUrlBack = new Image();
        imageUrlBack.onload = function () {
          var canvasImage = new fabric.Image(imageUrlBack);
          canvasImage.crossOrigin = "Anonymous"; //这里是主要添加的属性
          canvasImage.tagId = that.tagId;
          // canvasImage.setPrice = that.tagPrice;
          canvasImage
            .set({
              left: 45,
              top: 120,
            })
            .scale((492 - 90) / canvasImage.width);
          that.designBack.add(canvasImage);
          that.designBack._activeObject = canvasImage;
          that.designBack.renderAll();
        };
        imageUrlBack.crossOrigin = "Anonymous";
        imageUrlBack.src = img;
      }
    },
    // tab 切换
    tabCallBack(key) {
      this.tabKey = key;
    },
    onScroll() {
      const scrollHeight = this.$refs.scrollRef.scrollHeight;
      const scrollTop = this.$refs.scrollRef.scrollTop;
      const distanceBottom = scrollHeight - scrollTop - 300;
      if (distanceBottom < 100 && !this.isLoadingNFT && this.hasNext) {
        this.getMyNFT();
      }
    },
    async goConnect() {
      await walletWeb3modal.onConnect();
    },
    back() {
      this.nftIds = [];
      this.canDesign = false;
      this.open = false;
    },
    // 开始设计
    async goDesign() {
      this.getMyNFT(); // 获取我的nft列表
      this.isFrontActive = true;
      if (this.isExchangeFront) {
        this.setExchangeFront();
      }
      if (this.isExchangeBack) {
        this.setExchangeBack();
      }
      this.spinning = true;
      // this.$refs.canvas.setBackgroundColor("transparent");
      await this.handleDefaultBg(this.selectedProduct);
      this.initFabric();
      if (this.selectedProduct.options.length <= 2) {
        this.setColor("transparent");
      }
      if (!this.isOnly) {
        this.initFabricBack();
      }
      this.canDesign = true;
      this.open = true;
      if (this.selectedProduct.options[this.selectedColor].name === "white") {
        this.isBlack = false;
      } else {
        this.isBlack = true;
      }
      this.setDefaultBg();
      this.spinning = false;
    },
    initFabric() {
      const that = this;
      fabric.Object.prototype.objectCaching = false;
      if (!this.designIsFrist) {
        if (this.selectedCate === 4) {
          this.setCanvasSize(389.33, 389.33, 161.23, 161.23);
        } else if (this.isOnly) {
          this.setCanvasSize(400, 830, 80, 166);
        } else {
          this.setCanvasSize(492, 624, 80.3, 104);
        }
      } else {
        if (this.selectedCate === 4) {
          this.design.setWidth(389.33);
          this.design.setHeight(389.33);
          this.minimap.setWidth(161.23);
          this.minimap.setHeight(161.23);
          this.designWidth = 389.33;
          this.designHeight = 389.33;
        } else if (this.isOnly) {
          this.design.setWidth(400);
          this.design.setHeight(830);
          this.minimap.setWidth(80);
          this.minimap.setHeight(166);
          this.designWidth = 400;
          this.designHeight = 830;
        } else {
          this.design.setWidth(492);
          this.design.setHeight(624);
          this.minimap.setWidth(80.3);
          this.minimap.setHeight(104);
          this.designWidth = 492;
          this.designHeight = 624;
        }
      }
      this.designIsFrist = true;
      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16,
        offsetX: 16,
        cursorStyle: "pointer",
        mouseUpHandler: that.deleteObject,
        render: that.renderIcon,
        cornerSize: 24,
      });
      this.design.on("object:added", function () {
        that.updateCanvasState();
        that.updateMiniMap();
      });
      this.design.on("object:modified", function () {
        that.updateMiniMap();
      });
      document.addEventListener("keydown", keyboard);
      function keyboard(event) {
        let keycode = event.keyCode;
        if (keycode == 8) {
          that.deleteNftIDS(that.design);
          that.initalDeleteImage();
        }
      }
      this.design.on("mouse:down", function (opt) {
        if (that.design._activeObject?.id !== undefined) {
          for (let item of that.nftIds) {
            if (item.id === that.design._activeObject.id) {
              if (that.selectedCate !== 4) {
                that.setTagData(item);
              }
            }
          }
        }
        var evt = opt.e;
        if (evt.altKey === true) {
          that.isDragging = true;
          that.selection = false;
          that.lastPosX = evt.clientX;
          that.lastPosY = evt.clientY;
        }
      });
      this.design.on("mouse:move", function (opt) {
        if (that.isDragging) {
          var e = opt.e;
          var vpt = that.viewportTransform;
          vpt[4] += e.clientX - that.lastPosX;
          vpt[5] += e.clientY - that.lastPosY;
          that.requestRenderAll();
          that.updateMiniMapVP();
          that.lastPosX = e.clientX;
          that.lastPosY = e.clientY;
        }
      });
      this.design.on("mouse:up", function () {
        that.updateCanvasState();
        that.isDragging = false;
        that.selection = true;
      });

      this.design.loadFromJSON(
        {
          version: "3.6.3",
          objects: [],
        },
        function () {
          that.initMinimap();
          that.updateMiniMapVP();
        }
      );
    },
    setCanvasSize(designWidth, designHeight, minimapWidth, minimapHeight) {
      this.design = new fabric.Canvas("design", {
        width: designWidth,
        height: designHeight,
        containerClass: "design",
      });
      this.minimap = new fabric.Canvas("minimap", {
        width: minimapWidth,
        height: minimapHeight,
        containerClass: "minimap",
        selection: false,
      });
      this.designWidth = designWidth;
      this.designHeight = designHeight;
    },
    createCanvasEl() {
      var designSize = { width: this.designWidth, height: this.designHeight };
      var originalVPT = this.design.viewportTransform;
      // zoom to fit the this.design in the display canvas
      var designRatio = fabric.util.findScaleToFit(designSize, this.design);

      // zoom to fit the display the this.design in the minimap.
      var minimapRatio = fabric.util.findScaleToFit(this.design, this.minimap);

      var scaling = this.minimap.getRetinaScaling();

      var finalWidth = designSize.width * designRatio;
      var finalHeight = designSize.height * designRatio;

      this.design.viewportTransform = [
        designRatio,
        0,
        0,
        designRatio,
        this.design.getWidth() - finalWidth,
        this.design.getHeight() - finalHeight,
      ];
      var canvas = this.design.toCanvasElement(minimapRatio * scaling);
      this.design.viewportTransform = originalVPT;
      return canvas;
    },
    updateMiniMap() {
      var canvas = this.createCanvasEl();
      this.minimap.backgroundImage._element = canvas;
      this.minimap.requestRenderAll();
    },
    updateMiniMapVP() {
      this.minimap.requestRenderAll();
    },
    initMinimap() {
      var canvas = this.createCanvasEl();
      var backgroundImage = new fabric.Image(canvas);
      backgroundImage.scaleX = 1 / this.design.getRetinaScaling();
      backgroundImage.scaleY = 1 / this.design.getRetinaScaling();
      this.minimap.centerObject(backgroundImage);
      this.minimap.backgroundColor = "white";
      this.minimap.backgroundImage = backgroundImage;
      this.minimap.requestRenderAll();
    },
    handleNftId(target) {
      let item = this.nftIds;
      for (let index in item) {
        if (item[index].image === target) {
          return index;
        }
      }
    },
    deleteNftIDS(canvas) {
      let index;
      if (this.isFrontActive) {
        index = this.handleNftId(canvas._activeObject._element.currentSrc);
      } else {
        index = this.handleNftId(canvas._activeObject._element.currentSrc);
      }
      this.nftIds.splice(index, 1);
    },
    deleteObject(eventData, transform) {
      let target = transform.target;
      var canvas = target.canvas;
      if (target.text === undefined && target.tagId === undefined) {
        this.deleteNftIDS(target.canvas);
      }
      canvas.remove(target);
      if (this.isFrontActive) {
        this.updateMiniMap();
      } else {
        this.updateMiniMapBack();
      }
      canvas.requestRenderAll();
    },
    initalDeleteImage() {
      if (this.design._activeObject?.text) {
        return;
      }
      this.design.remove(this.design._activeObject);
      this.updateMiniMap();
      this.design.requestRenderAll();
    },
    renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var img = document.createElement("img");
      img.src = require("@/assets/images/customize/delete.png");
      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = "black";
      var width = 25;
      let height = 25;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(img, -width / 2, -height / 2, width, height);
      ctx.restore();
    },
    initFabricBack() {
      const that = this;
      fabric.Object.prototype.objectCaching = false;
      if (!this.designBackIsFrist) {
        this.designBack = new fabric.Canvas("designBack", {
          containerClass: "designBack",
        });
        this.minimapBack = new fabric.Canvas("minimapBack", {
          containerClass: "minimapBack",
          selection: false,
        });
      }
      this.designBackIsFrist = true;
      document.addEventListener("keydown", keyboard);
      function keyboard(event) {
        let keycode = event.keyCode;
        if (keycode == 8) {
          that.deleteNftIDS(that.designBack);
          that.initalDeleteImageBack();
        }
      }
      this.designBack.on("object:added", function () {
        that.updateCanvasStateBack();
        that.updateMiniMapBack();
      });
      this.designBack.on("object:modified", function () {
        that.updateMiniMapBack();
      });
      this.designBack.on("mouse:down", function (opt) {
        if (that.designBack._activeObject?.id !== undefined) {
          for (let item of that.nftIds) {
            if (item.id === that.designBack._activeObject.id) {
              that.setTagData(item);
            }
          }
        }
        let evt = opt.e;
        if (evt.altKey === true) {
          that.isDragging = true;
          that.selection = false;
          that.lastPosX = evt.clientX;
          that.lastPosY = evt.clientY;
        }
      });
      this.designBack.on("mouse:move", function (opt) {
        if (that.isDragging) {
          var e = opt.e;
          var vpt = that.viewportTransform;
          vpt[4] += e.clientX - that.lastPosX;
          vpt[5] += e.clientY - that.lastPosY;
          that.requestRenderAllBack();
          that.updateMiniMapVPBack();
          that.lastPosX = e.clientX;
          that.lastPosY = e.clientY;
        }
      });
      this.designBack.on("mouse:up", function () {
        that.updateCanvasStateBack();
        that.isDragging = false;
        that.selection = true;
      });

      this.designBack.loadFromJSON(
        {
          version: "3.6.3",
          objects: [],
        },
        function () {
          that.initMinimapBack();
          that.updateMiniMapVPBack();
        }
      );
    },
    createCanvasElBack() {
      var designSize = { width: this.designWidth, height: this.designHeight };
      var originalVPT = this.designBack.viewportTransform;
      // zoom to fit the this.designBack in the display canvas
      var designRatio = fabric.util.findScaleToFit(designSize, this.designBack);

      // zoom to fit the display the this.designBack in the minimap.
      var minimapRatio = fabric.util.findScaleToFit(
        this.designBack,
        this.minimapBack
      );

      var scaling = this.minimapBack.getRetinaScaling();

      var finalWidth = designSize.width * designRatio;
      var finalHeight = designSize.height * designRatio;

      this.designBack.viewportTransform = [
        designRatio,
        0,
        0,
        designRatio,
        this.designBack.getWidth() - finalWidth,
        this.designBack.getHeight() - finalHeight,
      ];
      var canvas = this.designBack.toCanvasElement(minimapRatio * scaling);
      this.designBack.viewportTransform = originalVPT;
      return canvas;
    },
    updateMiniMapBack() {
      var canvas = this.createCanvasElBack();
      this.minimapBack.backgroundImage._element = canvas;
      this.minimapBack.requestRenderAll();
    },
    updateMiniMapVPBack() {
      this.minimapBack.requestRenderAll();
    },
    initMinimapBack() {
      var canvas = this.createCanvasElBack();
      var backgroundImage = new fabric.Image(canvas);
      backgroundImage.scaleX = 1 / this.designBack.getRetinaScaling();
      backgroundImage.scaleY = 1 / this.designBack.getRetinaScaling();
      this.minimapBack.centerObject(backgroundImage);
      this.minimapBack.backgroundColor = "white";
      this.minimapBack.backgroundImage = backgroundImage;
      this.minimapBack.requestRenderAll();
    },
    initalDeleteImageBack() {
      if (this.designBack._activeObject?.text) {
        return;
      }
      this.designBack.remove(this.designBack._activeObject);
      this.updateMiniMapBack();
      this.designBack.requestRenderAll();
    },
    modalTabCallBack(key) {
      this.modalTabKey = key;
      switch (key) {
        case "0":
          this.handleStatusFalse();
          this.status.select = true;
          this.canvas.isDrawingMode = false; // 不允许绘画（返回普通框选模式）
          if (this.isEnter) {
            this.mattingImageSrc = this.getOperationImage();
          }
          this.isEnter = true;
          break;
        case "1":
          this.handleDrawChange("erase");
          break;
        case "2":
          this.handleDrawChange("undo");
          break;
        default:
          break;
      }
    },
    getOperationImage() {
      return this.canvas.toDataURL({
        multiplier: 1,
        format: "png",
        enableRetinaScaling: false,
      });
    },
    handleDrawChange(type) {
      this.handleStatusFalse();
      this.canvas.freeDrawingBrush = new fabric.EraserBrush(this.canvas);
      if (type === "undo") {
        this.status.undo = true;
        this.canvas.freeDrawingBrush.width = this.undoRadius;
        this.canvas.freeDrawingBrush.inverted = true;
        this.canvas.freeDrawingBrush.strokeLineCap =
          this.options[this.selectedRecoverShape].value;
      } else if (type === "erase") {
        this.status.erase = true;
        this.canvas.freeDrawingBrush.width = this.eraseRadius;
        this.canvas.freeDrawingBrush.strokeLineCap =
          this.options[this.selectedEreaserShape].value;
      }
      this.drawRadius = this.canvas.freeDrawingBrush.width;
      this.drawShape = this.canvas.freeDrawingBrush.strokeLineCap;
      this.canvas.isDrawingMode = true;
    },
    // 选择大分类case,clothes,houseware...
    async selectType(index) {
      if (index > 1 && index !== 4) {
        this.$message.warning("Coming Soon...");
        return;
      }
      // this.selectedProduct = null;
      let params = this.product[index].categoryText;
      if (params === "Painting") {
        params = "print";
      } 
      await this.getGoodsList(params);
      this.selectedCate = index;
    },
    async getGoodsList(category = "case") {
      this.selectedProduct.cover_image = false;
      category = category.toLowerCase();

      this.spinning = true;
      document.body.style.overflow = "hidden";

      try {
        const resp = await API.excute("goodsList", { category });

        this.goodsData = resp.data;
        this.defaultPrice = resp.data[0]?.price;
        this.selectedProduct = this.goodsData[0];
        this.remarksOption = this.selectedProduct.remarks
          ? this.selectedProduct.remarks.split("/")
          : [];
        this.selectVal = { key: this.goodsData[0].name };
        document.body.style.overflow = "";
        this.spinning = false;
      } catch {
        this.spinning = false;
        document.body.style.overflow = "";
      }
    },
    async getMyNFT() {
      let user_addr = this.address;
      const params = {
        address: user_addr,
        scroll: {
          start_cursor: this.startCursor,
          page_size: 20,
        },
      };
      this.isLoadingNFT = true;
      const { data, scroll_info } = await API.excute("getMyNfts", params);
      this.isLoadingNFT = false;
      this.info = this.info.concat(data);
      this.hasNext = scroll_info.has_next_page;
      this.startCursor = scroll_info.end_cursor;
    },
    handleTextLength(str, value = 20) {
      if (str.length >= value) {
        return str.slice(0, 5) + "..." + str.slice(-3);
      } else {
        return str;
      }
    },
    handleNftUrl(address, id) {
      return "https://opensea.io/assets/ethereum/" + address + "/" + id;
    },
    async setTagData(item) {
      this.canSetTag = false;
      this.tagId = item.id;
      this.nftName = item.name;
      let token = this.nftName.match(/#(\S*)/);
      if (token === null) {
        token = "无";
      } else {
        token = "#" + token[1];
      }
      let pureName = this.nftName.split("#")[0];
      if (pureName.length >= 15 && pureName.length <= 25) {
        this.lessen = true;
      } else if (pureName.length < 15) {
        this.lessen = false;
      } else {
        this.lessen = true;
        this.nftName = pureName.slice(0, 12) + "..." + pureName.slice(-12);
        pureName = pureName.slice(0, 12) + "..." + pureName.slice(-12);
      }
      this.nftNameObj = [pureName, token];
      const { data } = await API.excute("getNFTTradeList", {
        token_address: item.token_address,
        token_id: item.token_id,
      });
      if (data.length === 0) {
        this.contractAddress = "MINT";
      } else {
        this.contractAddress = data[0] + " ETH";
      }
      this.walletAddress = "Loading....";
      let address;
      if (this.tabKey + "" === "1") {
        address = this.address;
      } else {
        address = item.user.wallet_address;
      }
      let provider = await wallet.getProviderNow();
      let name = await provider.lookupAddress(address);
      if (name !== null) {
        name = name.toUpperCase();
        this.walletAddress = name;
      } else {
        this.walletAddress = this.handleTextLength(address);
      }
      let url = this.handleNftUrl(item.token_address, item.token_id);
      this.qrCodeShow = false;
      this.tagUrl = url;
      this.qrCodeShow = true;
      this.canSetTag = true;
    },
    handleNftInfo(item, price) {
      let index = this.getIndex(item.image);
      // let setPrice = price;
      // for (let value of this.nftIds) {
      //   if (value.id === item.id) {
      //     setPrice = "0";
      //   }
      // }
      let obj = {
        ...item,
        index,
        setPrice: price,
      };
      if (this.selectedCate === 4) {
        this.nftIds = [obj];
      } else {
        this.nftIds.push(obj);
      }
      this.getData();
      // this.setTagData(item);
      this.setTagData(item);
      return index;
    },
    chooseNFT(item, key, price) {
      let that = this;
      this.selectedNFT = key;
      let fommat = item.image.substring(item.image.length - 3);
      if (this.isFrontActive) {
        if (fommat === "svg") {
          this.$message.info("SVG matting is not currently supported!");
          return;
          // this.handleSvg(item.image, that.design);
        } else {
          let index = this.handleNftInfo(item, price);
          if (this.selectedCate === 4) {
            this.clearPrintCanvas();
          }
          var imageUrl = new Image();
          imageUrl.onload = function () {
            let canvasImage;
            if (that.selectedCate === 4) {
              canvasImage = new fabric.Image(imageUrl, {
                lockMovementX: true,
                lockScalingX: true,
              });
            } else {
              canvasImage = new fabric.Image(imageUrl);
            }
            canvasImage.crossOrigin = "Anonymous"; //这里是主要添加的属性
            let scaleData, positionData;
            if (that.selectedCate === 4) {
              scaleData = 390 / canvasImage.width;
            } else if (that.isOnly) {
              scaleData = (400 - 90) / canvasImage.width;
              positionData = {
                left: 45,
                top: 250,
              };
            } else {
              scaleData = (492 - 90) / canvasImage.width;
              positionData = {
                left: 45,
                top: 120,
              };
            }
            canvasImage.set(positionData).scale(scaleData);
            canvasImage.id = item.id;
            canvasImage.index = index;
            that.design.add(canvasImage);
            that.design._activeObject = canvasImage;
            that.design.renderAll();
          };
          imageUrl.crossOrigin = "Anonymous";
          imageUrl.src = item.image;
          this.updateMiniMap();
        }
      } else {
        if (fommat === "svg") {
          this.$message.info("SVG matting is not currently supported!");
          return;
          // this.handleSvg(item.image,that.designBack);
        } else {
          let index = this.handleNftInfo(item, price);
          var imageUrlBack = new Image();
          imageUrlBack.onload = function () {
            var canvasImage = new fabric.Image(imageUrlBack);
            canvasImage.crossOrigin = "Anonymous"; //这里是主要添加的属性
            canvasImage
              .set({
                left: 45,
                top: 120,
              })
              .scale((492 - 90) / canvasImage.width);
            canvasImage.id = item.id;
            canvasImage.index = index;
            that.designBack.add(canvasImage);
            that.designBack._activeObject = canvasImage;
            that.designBack.renderAll();
          };
          imageUrlBack.crossOrigin = "Anonymous";
          imageUrlBack.src = item.image;
          this.updateMiniMapBack();
        }
      }
    },
    clearPrintCanvas() {
      for (let item of this.design._objects) {
        if (item.id) {
          this.design.remove(item);
        }
      }
      this.updateMiniMap();
      this.design.requestRenderAll();
    },
    getIndex(target) {
      if (this.nftIds !== []) {
        for (let item of this.nftIds) {
          if (item.image === target) {
            let key = item.index + 1;
            return key;
          }
        }
      }
      return 0;
    },
    changeSize(item, i) {
      this.selectedSize = i;
    },
    // 选择黑白来改变背景
    changeBg(item, i) {
      this.selectedOption = item;
      this.selectedColor = i;
      if (!this.isOnly) {
        this.setColor(item.name);
      }
      if (item.name === "white") {
        this.isBlack = false;
        this.setDefaultBg();
      } else {
        this.isBlack = true;
        this.colorOption = [];
        if (this.isOnly) {
          if (this.selectedCate === 4) {
            this.colorOption = this.onlyBg.white;
          } else {
            this.colorOption = this.onlyBg.black;
          }
          if (this.design && this.minimap) {
            this.setTextColor(this.design, "white");
            this.design.renderAll();
            this.updateMiniMap();
          }
        } else {
          if (
            this.design &&
            this.designBack &&
            this.designBack &&
            this.minimap
          ) {
            this.setTextColor(this.design, "white");
            this.setTextColor(this.designBack, "white");
            this.design.renderAll();
            this.designBack.renderAll();
            this.updateMiniMap();
            this.updateMiniMapBack();
          }
          this.colorOption = this.notOnlyBg.black;
        }
      }
    },
    setTextColor(data, color) {
      if (data._iTextInstances !== undefined) {
        for (let item of data?._iTextInstances) {
          item.set("fill", color);
        }
      }
    },
    setDefaultBg() {
      if (this.isOnly) {
        if (this.design && this.minimap) {
          this.setTextColor(this.design, "black");
          this.design.renderAll();
          this.updateMiniMap();
        }
        this.colorOption = this.onlyBg.white;
      } else {
        if (this.design && this.designBack && this.designBack && this.minimap) {
          this.setTextColor(this.design, "black");
          this.design.renderAll();
          this.updateMiniMap();
          this.setTextColor(this.designBack, "black");
          this.designBack.renderAll();
          this.updateMiniMapBack();
        }
        this.colorOption = this.notOnlyBg.white;
      }
    },
    setColor(color, minimapColor = "") {
      if (minimapColor !== "") {
        this.minimap.set("backgroundColor", minimapColor);
      } else {
        this.minimap.set("backgroundColor", color);
      }
      if (!this.isOnly) {
        this.minimapBack.set("backgroundColor", color);
        this.minimapBack.renderAll();
      }
      this.design.renderAll();
      this.minimap.renderAll();
    },

    // 结账
    async goCheck() {
      if (this.selectedCate === 4 && !this.canSetTag) {
        this.$message.info("Tag is being generated, please try again later!");
        return;
      }
      this.design.discardActiveObject().renderAll();
      if (!this.isOnly) {
        this.designBack.discardActiveObject().renderAll();
      }
      this.spinning = true;
      try {
        this.bgIsNone = true;
        let imgUrl = await this.getImg();
        this.tagInfo = [];
        await this.handleTagInfo(this.design._objects);
        await this.handleNftSize(this.design._objects);
        this.texts = [];
        this.handleText(this.design._iTextInstances);
        if (!this.isOnly) {
          await this.handleTagInfo(this.designBack._objects);
          await this.handleNftSize(this.designBack._objects);
          this.handleText(this.designBack._iTextInstances);
        }
        let texts = await imgListToCdn(this.texts);
        let nftIds = this.nftIds.map((item) => {
          return {
            id: item.id,
            size: {
              height: item.width,
              width: item.height,
            },
            position: {
              x: item.top,
              y: item.left,
            },
            x_mirror_flip: item.flipX,
            y_mirror_flip: item.flipY,
            price: item.setPrice,
            nft_image_designed: item.image,
          };
        });

        // for (let data of nftIds) {
        //   for (let index in this.tagInfo) {
        //     if (this.tagInfo[index].nft_id === data.id) {
        //       this.tagInfo[index].price = "0";
        //     }
        //   }
        // }
        let total = 0;
        for (let item of this.nftIds) {
          total += Number(item.setPrice ? item.setPrice : 0);
        }
        // console.log(this.nftIds, "jsdj");
        // console.log(total, "total");
        // for (let item of this.tagInfo) {
        //   total += Number(item.price);
        // }
        // checkout后保存选中商品
        const saveData = {
          imgUrl,
          title: this.product[this.selectedCate].categoryText.toLowerCase(),
          type: this.selectedProduct.name,
          price: this.selectedProduct.price,
          goodsId: this.selectedProduct.id,
          goodsOption: this.selectedProduct.options[this.selectedColor],
          goodsSize: this.remarksOption[this.selectedSize],
          nftIds,
          nftsPrice: total,
          texts,
          tags: this.tagInfo,
          count: 1,
        };
        this.saveOrder(saveData);
        this.spinning = false;
        this.show = true;
      } catch {
        this.spinning = false;
        this.$message.info("Please try again!");
      }
    },
    async handleTagInfo(data) {
      for (let item of data) {
        if (item.index === undefined && item.text === undefined) {
          let width = ((item.width * item.scaleX * 2.54) / 72).toFixed(2);
          let height = ((item.height * item.scaleY * 2.54) / 72).toFixed(2);
          let image = await dataUrlToCdn([item._element?.currentSrc]);
          this.tagInfo.push({
            nft_id: item.tagId,
            image_url: image[0],
            size: {
              width,
              height,
            },
            price: 0,
          });
        }
      }
    },
    async handleNftSize(data) {
      for (let item of data) {
        for (let k of this.nftIds) {
          if (k.id === item?.id && k.index === item?.index) {
            k.width = ((item.width * item.scaleX * 2.54) / 72).toFixed(2);
            k.height = ((item.height * item.scaleY * 2.54) / 72).toFixed(2);
            k.left = item.left ? ((item.left * 2.54) / 72).toFixed(2) : 0;
            k.top = ((item.top * 2.54) / 72).toFixed(2);
            k.flipX = item.flipX ? item.flipX : false;
            k.flipY = item.flipY  ? item.flipY : false;
            if (item._element?.currentSrc.slice(0, 4) === "data") {
              k.image = await dataUrlToCdn([item._element?.currentSrc]);
              k.image = k.image[0];
            } else {
              k.image = item._element?.currentSrc;
            }
          }
        }
      }
    },
    async handleText(data) {
      if (data !== undefined) {
        for (let item of data) {
          this.texts.push(
            await textToImage(
              item.text,
              this.selectedProduct.options[this.selectedColor].name
            )
          );
        }
      }
    },
    // 选择小分类，iphone 12, iphone 12 pro...
    // or T-shirt
    async selectChange(val) {
      this.selectedProduct = find(
        this.goodsData,
        (item) => item.id === val.key
      );
      await this.handleDefaultBg(this.selectedProduct);
      this.setDefaultBg();
    },
    async handleDefaultBg(item) {
      if (item.options.length > 2) {
        this.isOnly = false;
        await Promise.all([
          convertUrlToBase64(item.options[0].image),
          convertUrlToBase64(item.options[1].image),
          convertUrlToBase64(item.options[2].image),
          convertUrlToBase64(item.options[3].image),
        ]).then(([src1, src2, src3, src4]) => {
          this.notOnlyBg = {
            white: [
              {
                src: src1,
              },
              {
                src: src2,
              },
            ],
            black: [
              {
                src: src3,
              },
              {
                src: src4,
              },
            ],
          };
        });
      } else if (item.options.length === 2) {
        this.isOnly = true;
        await Promise.all([
          convertUrlToBase64(item.options[0].image),
          convertUrlToBase64(item.options[1].image),
        ]).then(([src1, src2]) => {
          this.onlyBg.white = [
            {
              src: src1,
            },
          ];
          this.onlyBg.black = [
            {
              src: src2,
            },
          ];
        });
      } else {
        this.isOnly = true;
        await Promise.all([convertUrlToBase64(item.options[0].image)]).then(
          ([src1]) => {
            this.onlyBg.white = [
              {
                src: src1,
              },
            ];
          }
        );
      }
    },
  },
  async mounted() {
    this.getGoodsList();
  },
  created() {},
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
