/**
 * 合约相关配置
 * Author: Thompson
 */
export const chainId = process.env.NODE_ENV === "production" ? "0x1" : "0x4";

// 合约地址
export const contract_address =
  process.env.NODE_ENV === "production"
    ? "0x98C8790811432E714f2b76c368C45e1E0A5Ad4ee"
    : "0x4057702B96Bf679527dCf35934c31d0e92F3c0a6";

// 合约 abi 信息
export const contract_abi = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    inputs: [{ internalType: "uint256", name: "orderId", type: "uint256" }],
    name: "chekOrder",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "orderId", type: "uint256" }],
    name: "payOrder",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "to", type: "address" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];
