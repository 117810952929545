<template>
  <div>
    <div class="main-container">
      <special-view
        v-if="showSpecial"
        :specialList="specialList"
        :selectedSpecial="selectedSpecial"
        @changeSpecial="changeSpecial"
        @createOrder="createOrder"
        @changeSpec="changeSpec"
      ></special-view>
      <!-- <EmptyView v-if="!selectedSpecial"></EmptyView> -->
      <check-view
        @backToSpecial="backToSpecial"
        @paySuccess="paySuccess"
        v-if="!showSpecial"
      ></check-view>
    </div>
    <Footer v-if="!paid" />
    <Loading :tip="'loading...'" :spinning="showLoading" />
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import CheckView from "./CheckView";
import SpecialView from "./SpecialView";
import * as API from "@api/request";
import { mobileType } from "@/data";
export default {
  data() {
    return {
      showSpecial: true,
      specialList: [],
      selectedSpecial: {},
      showLoading: false,
      paid: false,
    };
  },
  components: {
    SpecialView,
    CheckView,
    Footer,
    Loading: () => import("@/components/Loading"),
  },
  methods: {
    async getSpecialList() {
      this.showLoading = true;
      const { data } = await API.excute("getSpecialList");
      this.specialList = data;
      this.selectedSpecial = this.specialList[0];
      this.handelSpecInit();
      this.showLoading = false;
    },
    handelSpecInit() {
      const { remarks, product_type } = this.selectedSpecial;
      if (product_type === "case") {
        this.selectedSpecial.specs = mobileType;
        this.$set(this.selectedSpecial, "currSpec", "iPhone XS Max");
      } else {
        if (remarks) {
          const specs = remarks.split("/");
          this.selectedSpecial.specs = specs;
          this.$set(this.selectedSpecial, "currSpec", specs[0]);
        } else {
          this.selectedSpecial.specs = [];
          this.$set(this.selectedSpecial, "currSpec", "");
        }
      }
      // console.log(this.selectedSpecial);
    },
    changeSpecial(item) {
      this.selectedSpecial = item;
      this.handelSpecInit();
    },
    createOrder() {
      this.showSpecial = false;
    },
    backToSpecial() {
      this.paid = false;
      this.showSpecial = true;
    },
    paySuccess() {
      this.paid = true;
    },
    changeSpec(spec) {
      this.$set(this.selectedSpecial, "currSpec", spec);
    },
  },
  mounted() {
    this.paid = false;
    this.getSpecialList();
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  margin-bottom: 60px;
}
</style>
