<template>
  <div id="conditions">
    <div class="main-container mt_40">
      <div class="conditions-title">TERMS & CONDITIONS</div>
      <div class="conditions-content">
        <p>
          Welcome to nplus.zone, owned and operated by nplus Labs Limited, a
          Hong Kong limited liability company (“nplus”, “we”, “us” or “our”).
          <br />
          <br />
          1.Acceptance of Terms
          <br />
          <br />
          These Terms and Conditions, including all policies referred to herein
          (these "Terms and Conditions"), apply to (a) your use of or access to
          this website and (b) your purchase of our products on this website.
          Please review these Terms and Conditions carefully before accessing or
          using this website. If you do not accept these Terms and Conditions,
          you should discontinue your use of this website.
          <br />
          <br />
          By accessing or using this website, (i) you are agreeing to these
          Terms and Conditions, which may be updated, modified and amended by us
          from time to time without prior notice, and your continued use or
          access of this website shall be considered as your acceptance of the
          updated version of these Terms and Conditions; and (ii) you represent
          and warrant that (1) you are of legal age under the laws of the
          country/state where you reside and have full legal capacity to legally
          agree to these Terms and Conditions; and (2) you have read, fully
          understand and agree to be bound by the updated version of these Terms
          and Conditions; and (3) you will use the website in accordance with
          applicable law.
          <br />
          <br />
          2. Service description
          <br />
          <br />
          This website allows you to create your products with NFTs. NFTs
          (non-fungible tokens) are digital tokens representing any asset, from
          digital painting, to GIFs, to music albums.
          <br />
          <br />
          You may create and purchase your own NFT products by linking your
          digital wallets on MetaMask or WalletConnect. All transactions that
          take place on this website are facilitated and run by third-party
          electronic wallet extensions, and by accessing and using our website
          you agree that you are governed by the terms of services and privacy
          policy for the applicable extensions.
          <br />
          <br />
          Payment is accepted in Ethereum (ETH), and we may elect to accept
          payment in other cryptocurrencies from time to time in which case we
          will provide relevant information on this website.
          <br />
          <br />
          3. Intellectual Property
          <br />
          <br />
          All content and materials, including, without limitation, text,
          graphics, logos, images, video and other software on this website
          (collectively, the "Website Content") are protected by copyright,
          trademark, patent, trade secret, database and other intellectual
          property rights, which is either owned by or licensed to us. We hereby
          grant you a limited, revocable, non-sublicensable license to reproduce
          and display the Website Content (excluding any software code) solely
          for viewing and using this website. You may not copy, reproduce, make
          available online or transmit, publish, adapt, distribute, transmit,
          broadcast, display, sell, license, or otherwise exploit any of the
          Website Content without written permission from us or the owner of the
          intellectual property rights. All rights, title, and interest in all
          intellectual property rights in all of nplus Labs Limited's tradename,
          brands, logos, images, text, content and products and services as
          displayed on this website are solely owned by us and will remain or be
          vested in us at all times. These Terms and Conditions and your use of
          this website in any event would not constitute a transfer, assignment
          or grant to you any of our or the third party's intellectual property
          rights.
          <br />
          <br />
          4. Third Party Links/Information
          <br />
          <br />
          This website may contain links to third party sites that are not
          affiliated with us. When you access a third-party site, please
          understand that we do not control the content and are not responsible
          for their content, use, privacy policies, or practices. By linking to
          a third-party site, we do not represent or imply that we endorse such
          third-party site. You are responsible for taking precautions as
          necessary to protect yourself and your computer systems from viruses,
          worms, trojan horses, and other harmful or destructive content. We
          suggest that you carefully review the privacy policies of each site
          you visit. These other sites may send their own cookies to users,
          collect information, or solicit personal information. We disclaim any
          and all responsibility for any harm resulting from your use of the
          third-party sites.
          <br />
          <br />
          You understand that (a) this website may contain adverting and
          sponsorship material of third party; (b) when using this website, you
          will be exposed to content from internet sites or sources outside this
          website; (c) we are not responsible for the accuracy, usefulness,
          safety, or intellectual property rights of or relating to such content
          and materials; (d) you further understand and acknowledge that you may
          be exposed to content that is inaccurate, offensive, indecent or
          objectionable, and you agree to waive any legal or equitable rights or
          remedies you have or may have against us with respect to the aforesaid
          <br />
          <br />
          5. Service and Products
          <br />
          <br />
          Although we aim to offer you the best service possible, we make no
          promise that the services on this website will meet your requirements
          and that the services will be fault-free. If a fault occurs with this
          website, you should report it and we will attempt to correct the fault
          as soon as it reasonably can. Your access to this website may be
          occasionally restricted to allow for repairs, maintenance or the
          introduction of new facilities or services. We will attempt to restore
          the service as soon as we reasonably can.
          <br />
          <br />
          We would make reasonable efforts to display the attributes and
          features of our products accurately, but we cannot guarantee that your
          device will accurately display the color of our products. All
          information, including, without limitation, description, images, and
          prices, of the products on this website, are subject to change at any
          time without notice.
          <br />
          <br />
          We reserve the right to limit the available quantity of or discontinue
          any product or service, or to bar any visitor from using this website
          or making any order on this website. We shall not be liable to you or
          to any third-party for any change of the information, price change,
          suspension, or discontinuance of the sale of any products.
          <br />
          <br />
          We make no promise that the content and material on this website is
          appropriate or available for use in locations outside Hong Kong SAR.
          Access to this website from locations where its contents are by law
          illegal or unlawful is prohibited. If you choose to access the website
          from locations outside Hong Kong SAR, you do so on your own initiative
          and you will be entirely responsible for compliance with local laws.
          <br />
          <br />
          6. Privacy Policy
          <br />
          <br />
          We collect and process the personal data of the visitors of this
          website in accordance with legal requirements and our Privacy Policy.
          The Privacy Policy shall form part of these Terms and Conditions.
          <br />
          <br />
          7. Registration
          <br />
          <br />
          If we opt to utilize a registration procedure, you agree to provide
          and maintain true, accurate, current, and complete information as
          requested by us.
          <br />
          <br />
          If we opt to offer accounts, you are furthermore responsible for
          maintaining the confidentiality of your account and password and are
          solely responsible for all activities that occur under your password
          or account. You undertake to promptly notify us of any unauthorized
          use of your password or account or any other actual or suspected
          security breach, and furthermore to ensure that you log out from your
          account at the end of each session when accessing the Platform. We
          will not be liable for any loss or damage arising from your failure to
          comply with this section.
          <br />
          <br />
          We reserve the right to refuse registration of any account name that
          violates a trademark or may mislead other users. In addition, by
          registering an account with us on this website, you agree that we can
          use any of the address and/or contact method you provide to us
          (including information submitted when you place an order) for
          promotional purpose related to nplus Labs.
          <br />
          <br />
          We may remove or terminate your account immediately without prior
          notice: (a) if any description or keyword that we consider
          inappropriate or unlawful, or otherwise likely to cause us liability,
          harm, or damages; or (b) if there has been no activity in your account
          for a period of one year; or (c) your activities on this Website
          violate these Terms and Conditions. You shall not use another's
          account without permission. We will not be liable for any acts or
          omissions by you, including any damages of any kind incurred because
          of such acts or omissions.
          <br />
          <br />
          8. Your Content
          <br />
          <br />
          We do not claim ownership of Your Content including your NFTs. When
          you upload content to this website, including any NFTs, you are and
          remain the owner of Your Content. By submitting any content and
          information, including, without limitation, any ideas, concepts,
          artworks, designs, photographs or likenesses, graphics, video, audio,
          data, information, comments or any other postings or content
          (collectively, "Your Content") to this website, (a) you agree to grant
          to us a perpetual, worldwide, irrevocable, royalty-free, non-exclusive
          and sub-licensable right and license to modify, edit, create
          derivative work of, disseminate, publish, display, distribute and
          otherwise use Your Content in any manner and for any lawful purpose,
          subject to the Privacy Policy; (b) you represent and warrant that (i)
          you own all right, title and interest in Your Content, or you have
          obtained all rights clearance and all necessary permissions and
          approval to submit Your Content to this website; (ii) neither Your
          Content nor your posting, uploading, publication, submission or
          transmittal of the Your Content or our use and exercise of the rights
          in Your Content as granted to us under these Terms and Conditions
          would violate any applicable law or regulation, or infringe
          intellectual property rights and any other rights (including, without
          limitation, copyright, trademark, patent, trade secret, moral right,
          publicity, privacy and contract rights) of any person or third party;
          (iii) Your Content do not contain (1) any material which is, or
          suspect to be, unlawful, threatening, defamatory, libelous, obscene,
          containing the naked body or intimate parts of a real person or could
          constitute or encourage conduct that would be considered a criminal
          offense or give rise to civil liability, or otherwise violate any law
          or regulation; (2) any bugs, viruses, worms, malware, trojan horses or
          other harmful or destructive content; (3) any content which is a spam,
          is machine- or randomly-generated; (4) unethical or unwanted
          commercial content designed to drive traffic to third party sites or
          boost the search engine rankings of third party sites, or to further
          unlawful acts (such as phishing) or mislead recipients as to the
          source of the material (such as spoofing); and (5) any pornographic
          material, profanity language, threats or material which incites
          aggression towards individuals or entities and/or spreading
          violence/hatred; and (c) you further agree to indemnify us and keep us
          indemnified against all costs, expenses, claims, damages, losses and
          liabilities incurred or suffered by us arising from Your Content. We
          reserve the right to remove any of Your Content which we consider
          inappropriate or unacceptable, and we do not guarantee that Your
          Content will not be lost or damaged or that you will be able to
          retrieve Your Content.
          <br />
          <br />
          9. Terms of Sale
          <br />
          <br />
          Offer to Purchase. All products available on this website is only an
          invitation to treat and shall not constitute an offer or unilateral
          contract. Your order is an offer to purchase from us the product(s)
          listed in your order under these Terms and Conditions.
          <br />
          <br />
          Ordering. You may need to set your browser to accept both cookies and
          pop-ups to be able to use all the functionalities of this website
          (e.g., to add products to your shopping cart and submitting your
          order). The orders placed on this website are subjecting to being
          charged with import fees, taxes, custom duties, related package
          handling fees and/or any other relevant charges required by the
          corresponding designated country and/or the organization(s) that
          handle(s) the shipment / delivery of the orders. None of these charges
          are included in the price that you pay on this website. Items cannot
          be sent as gifts, and your purchase may or may not have these charges,
          depending on the policies of the designated country and/or the
          organization(s) that handle(s) the package. If any of the said charges
          apply, you may need to pay for them to the respective collection
          authority in advance of receiving your purchase. You should check with
          any related authority of your country (or the designated country of
          the order) for any charges required to receive the goods you order
          from us. We are not responsible for any of these charges and would not
          refund on item(s) returned to us due to unaccepted / undelivered
          and/or any other reasons unless otherwise agreed with us in advance.
          In the event a product is listed on this website at an incorrect price
          or with incorrect information, orders for such products will be
          revised to reflect the correct price or information. This website only
          sells products to adults who have payment ability. If you are under 18
          years, please use this website with participation of your parent or
          guardian.
          <br />
          <br />
          Returns And Exchange.
          <br />
          <br />
          The Return and Exchange policy on this website shall form part of
          these Terms and Conditions.
          <br />
          <br />
          Promotional/Discount Code. If we offer any applicable
          promotional/discount codes, all such promotional/discount codes shall
          be subject to any additional terms and conditions as notified by us.
          Only one promotional code will be accepted per order. We reserve the
          rights (a) not to accept or to impose conditions on the honoring of,
          promotional code or other similar promotions; or (b) to cancel an
          order if an unauthorized promotional code has been used. All
          promotional discounts are non-transferable and there is no cash
          alternative.
          <br />
          <br />
          Not For Resale.
          <br />
          <br />
          This website is intended solely to sell products direct to end
          consumers, and purchase of products on this website for resale is
          strictly prohibited. We reserves the right to take any action against
          you, including, without limitation, to restrict your assess to or use
          of this website, cancel your orders and/or suspend or close your
          account, if we believe you are involved in purchase for resale.
          <br />
          <br />
          Right To Reject Orders
          <br />
          <br />
          We reserve the right to reject or cancel any orders placed on this
          website, without being liable for any damages or costs other than
          refund of any amount received from you in relation to the relevant
          order, for any reasons, including, without limitation, (a) the product
          is not available or out of stock; (b) we could not deliver to the
          address you provided to us; (c) your billing information is inaccurate
          or is not verifiable; (d) we believe you are under the age of 18 years
          old, or you are a reseller; (e) we suspect that your order is an
          unusual order or an order susceptible to or suspected of fraud; (f)
          there was pricing or other errors in the website information; or (g)
          any other reason beyond our reasonable control.
          <br />
          <br />
          10. Shipping/Delivery of Your Order
          <br />
          <br />
          The delivery type "Standard Shipping" is done via international
          registered articles while the type "Express Shipping" is sent via
          various courier services such as but not limited to FedEx, DHL
          Express.
          <br />
          <br />
          The way the package is presented to the recipient will depend on the
          postal service provided in the designated country. Most of the time
          packages are delivered by postman to the address and require the
          recipient to sign to receive the package. However, this may not be
          applicable to some countries or areas in the country and recipients
          may sometimes be required to pick up the package from the postal
          service's office. Please contact the national postal service of your
          country to check the way packages are delivered.
          <br />
          <br />
          Since shipping is often affected by the processing time of the Customs
          and delivery standard in the destination country, the estimated
          delivery date indicated on this website is strictly for reference only
          and shall not be regarded as a commitment. We do not guarantee orders
          to be delivered by a particular time and shall not be liable for any
          direct/indirect loss caused by delivery issues. If there is a
          necessity to check the status of the article in the destination
          country with local postal service, you will need to carry out the
          enquiry on your own and we will not be responsible for any fees (such
          as communications) incurred.
          <br />
          <br />
          Orders may or may not entitle free shipping. For the free shipping
          offer that we provide, the type of delivery to be adopted will be
          "Standard Shipping" unless otherwise specified. Free shipping, if
          offered, will be applicable only to the first shipment of the order.
          If the first shipment is not received and a re-shipment is required,
          re-shipment fee will be required in such cases. Reasons for shipment
          being unable to receive may include but not limited to, wrong
          recipient information and/or address provided during checkout (which
          will appear in the order confirmation email), recipient unable to pick
          up the package from local postal service. Exemptions of re-shipment
          fee may be granted for situations such as article being lost by the
          postal service, or the product is found to be defective when received.
          All shipping fees paid are non-refundable.
          <br />
          <br />
          Other delivery and shipping policies on this website shall form part
          of these Terms and Conditions.
          <br />
          <br />
          11. Restrictions on Use
          <br />
          <br />
          While you are visiting this website or accessing or using any service
          or features on this website, you must conduct yourself in a polite,
          courteous, and respectful manner, comply with all applicable laws,
          rules and regulations and supply truthful and non-misleading
          information. In addition to other restrictions set forth herein, you
          are prohibited from using this website and any content on this
          website: (a) for any unlawful purpose; (b) to disseminate any
          unlawful, harassing, libelous, abusive, threatening, harmful, vulgar,
          obscene, or otherwise objectionable material or otherwise breaching
          any laws, or to solicit others to perform or participate in any
          unlawful acts; (c) to transmit material that encourages conduct that
          constitutes a criminal offence, or otherwise breaches any applicable
          laws, regulations or code of practices; (d) to infringe or violate any
          of our or third party’s intellectual property rights; (e) to track or
          collect the personal information of others; (f) to submit false or
          misleading information; (g) to interfere with any other person’s use
          or enjoyment of this website; (h) to upload or transmit viruses or any
          other type of malicious code that will or may affect the functionality
          or operation of this website; and (i) to interfere with or circumvent
          the security features of this website. You hereby agree to indemnify
          us and keep us indemnified against all costs, expenses, damages,
          losses, and liabilities incurred or suffered by us arising from your
          breach of this provision. We reserve the right to terminate your use
          of this website which we consider violating this provision and any
          other terms of these Terms and Conditions, or inappropriate or
          unacceptable.
          <br />
          <br />
          12. Visitors' Content
          <br />
          <br />
          We have not evaluated, and cannot evaluate, all of the contents posted
          by any visitors (i.e. persons assessing this website) to this website
          ("Visitors' Contents"). By accessing this website or using any
          Visitors’ Content on this website, you agree and acknowledge that (a)
          we shall have no responsibility or liability in connection with the
          Visitors’ Contents, your use or reliance of any of such content, or
          their use or effects; (b) we do not stand for or imply that we
          supports the Visitors' Contents, or that we believe such Visitors'
          Contents to be accurate, useful or without harm; (c) this website may
          contain Visitors' Contents that (i) are offensive, indecent, or
          otherwise objectionable, as well as containing technical inaccuracies,
          typographical mistakes, and other errors; or (ii) violate the privacy
          or publicity rights, or breach the intellectual property and other
          proprietary rights, of third parties, or the downloading, copying or
          use of which are subject to additional terms and conditions, express
          or implied; and (d) you are solely responsible for taking precautions
          as necessary to defend yourself and your computer systems from bugs,
          viruses, worms, trojan horses, and other harmful or destructive
          content. We disclaim any and all responsibility for any harm resulting
          from the use by visitors of this website, the submission, posting and
          display of Visitors' Content on this website or from any downloading
          by those visitors of any Visitors’ Content on this website.
          <br />
          <br />
          13. Copyright Infringement
          <br />
          <br />
          As we ask others to respect our intellectual property rights, we
          respect the intellectual property rights of others. If you believe
          that material located on or linked to or from this website violates
          your copyright, you are encouraged to notify us. We will use our
          reasonable endeavors to respond to all such notices, and if required
          or appropriate, remove the infringing material or disable all links to
          the infringing material. We may, but has no obligation, to end a
          visitor’s permission to visit and use this website if, under
          appropriate conditions, the visitor is determined to be a repeat
          infringer of the copyrights or other intellectual property rights of
          nplus Labs or others.
          <br />
          <br />
          If you believe that your intellectual property rights have been
          infringed by a user of our service, please provide us with a
          notification that contains the following information:
          <br />
          <br />
          A physical or electronic signature of a person authorized to act on
          behalf of the owner of an exclusive right that is allegedly infringed.
          <br />
          <br />
          Identification of the copyrighted work claimed to have been infringed,
          or, if multiple copyrighted works at a single online site are covered
          by a single notification, a representative list of such works at that
          site.
          <br />
          <br />
          Identification of the material that is claimed to be infringing or to
          be the subject of infringing activity and that is to be removed or
          access to which is to be disabled, and information reasonably
          sufficient to permit the service provider to locate the material.
          <br />
          <br />
          Information reasonably sufficient to permit the service provider to
          contact the complaining party, such as an address, telephone number,
          and, if available, an electronic mail address at which the complaining
          party may be contacted.
          <br />
          <br />
          A statement that the complaining party has a good faith belief that
          use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law. A statement that the
          information in the notification is accurate, and under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed.
          <br />
          <br />
          14. Indemnification
          <br />
          <br />
          You agree to indemnify and hold harmless, to the fullest extent
          permitted under the applicable law, nplus Labs, our affiliates,
          suppliers and service provides, contractors, licensors and licensees,
          and our/their respective directors, officers, members, employees and
          agents, and our/their respective directors, officers, members,
          employees and agents from and against any and all claims, demands,
          disputes, actions, proceedings, causes of action, judgments, damagers,
          losses, liabilities, costs or expenses (including without limitation,
          attorneys’ fees and expenses, and all costs and expenses incurred in
          the recovery of the aforesaid amounts), arising out of (a) your use of
          this website; (b) Your Content; (c) your breach of these Terms and
          Conditions; (d) your violation of any applicable laws and regulation,
          or infringement of third party rights.
          <br />
          <br />
          15. Disclaimers Of Warranties

          <br />
          <br />
          This website and any products or services made available through this
          website are provided “as is” without any warranties of any kind,
          whether express, implied, or statutory. You agree that you must
          evaluate, and you bear all risks associated with, the use of this
          website, including, without limitation, any reliance on the accuracy,
          completeness, or usefulness of any materials available through this
          website. We, our affiliates, suppliers and service provide disclaim
          all warranties of any kind, express or implied, including, without
          limitation, the warranties of merchantability, fitness for a
          particular purpose and non-infringement of any intellectual property
          rights, other proprietary rights or statutory requirements, to the
          fullest extent permissible under applicable law. Neither we nor our
          affiliates, suppliers and service provide make any warranty that this
          website will be error free or that access thereto will be continuous
          or uninterrupted.
          <br />
          <br />
          16. Acknowledgement of Other Risk
          <br />
          <br />
          There are risks associated with using an Internet based currency,
          including but not limited to, the risk of hardware, software and
          Internet connections, the risk of malicious software introduction, and
          the risk that third parties may obtain unauthorized access to
          information stored within your wallet. You accept and acknowledge that
          we will not be responsible for any communication failures,
          disruptions, errors, distortions or delays you may experience when
          using services on this website, however caused.
          <br />
          <br />
          You further acknowledge that the regulatory regime governing
          blockchain technologies, cryptocurrencies, and tokens is uncertain,
          and new regulations or policies may materially adversely affect the
          development of this website and the utility or value of NFTs and your
          NFT phone case.
          <br />
          <br />
          17. Limitation of Liability
          <br />
          <br />
          To the fullest extent permissible under applicable law, in no event
          shall nplus Labs, our affiliates, suppliers and service provides,
          contractors, licensors and licensees, and our/their respective
          directors, officers, members, employees and agents be liable for (a)
          any indirect, incidental, consequential, special, exemplary or
          punitive damages of any kind, including, without limitation, damages
          for loss of profits, use, data, loss of other intangibles or loss of
          security of information you have provided in connection with your use
          of this website; and (b) damages of any kind resulting from your use
          of or inability to use this website or from any content posted on this
          website by us or any third party unless such damage is the direct
          result of our willful misconduct of. Your sole and exclusive remedy
          for dissatisfaction with this website, or services or products on this
          website, is to cease the use of this website. The maximum liability of
          nplus Labs, our affiliates, suppliers and service provide,
          contractors, licensors and licensees, for all damages, losses and
          causes of action, whether in contract, tort (including, without
          limitation, negligence) or otherwise, shall not exceed the fees paid
          to us by you during the twelve (12) month period prior to the accrual
          of the cause of action. We shall have no liability for any failure or
          delay due to matters beyond our reasonable control.
          <br />
          <br />
          18. Termination
          <br />
          <br />
          We reserve the right to at any time, with or without any reason and
          without any liability to you and any third party, (a) suspend or
          terminate your Account or your use of this website; (b) suspend,
          modify, alter, change, cease or discontinue all or any part of this
          website, any product or service available on this website. All
          provisions of these Terms and Conditions which by their nature should
          survive termination shall survive termination, including, without
          limitation, ownership provisions, warranty disclaimers, indemnity, and
          limitations of liability.
          <br />
          <br />
          19. Changes
          <br />
          <br />
          We reserve the right, at our sole discretion, to modify, change, amend
          or update these Terms and Conditions at any time. By continuing to
          access or use this website after those changes become effective, you
          agree to be bound by the updated version of these Terms and
          Conditions. If you do not agree to the new terms, please stop
          accessing and using this website.
          <br />
          <br />
          20. Governing Law and Jurisdiction These Terms and Conditions
          (including all policies referred to herein), and any access to or use
          of this website, shall be governed by the laws of Hong Kong. The
          parties agree to submit to the exclusive jurisdiction of the Hong Kong
          courts.
          <br />
          <br />
          21. Miscellaneous
          <br />
          <br />
          Force Majeure. We shall not be responsible for or liable for any
          failure to fulfil any obligations under these Terms and Conditions due
          to any Force Majeure event. “Force Majeure” means any event or cause
          not within our control and not caused by our wrongful act, neglect, or
          default, including but not limited to act of God, endemic/pandemic,
          flood, earthquake, storm, fire, war, riot, rebellion, civil commotion,
          any act, order, direction, or regulation of any government or any
          public, local or regulatory authority.
          <br />
          <br />
          No partnership. These Terms and Conditions do not, and shall not be
          construed to, create any partnership, joint venture,
          employer-employee, agency, or franchisor-franchisee relationship
          between you and nplus Labs.
          <br />
          <br />
          Severability. If any provision of these Terms and Conditions is found
          to be unlawful, void or for any reason unenforceable, such provision
          will be deemed severable from these Terms and Conditions and will not
          affect the validity and enforceability of any remaining provision.
          <br />
          <br />
          Assignment. Unless with our prior written consent, you shall not
          assign, transfer, or sublicense any or all of your rights or
          obligations under these Terms and Conditions.
          <br />
          <br />
          Third Party Rights. Other than nplus Labs and its group member
          companies, no other person shall have any right to enforce or enjoy
          the benefit of any of these Terms and Conditions.
          <br />
          <br />
          Entire Agreement. These Terms and Conditions, together with all
          policies referred to herein, constitute the entire agreement between
          nplus Labs and you relating to the subject matter herein and
          supersedes any and all prior or contemporaneous written or oral
          agreements or understandings between nplus Labs and you relating to
          the subject matter herein.
        </p>
      </div>
      <a-back-top />
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";
export default {
  name: "Conditions",
  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
#conditions {
  overflow: hidden;
  background-color: #252525;
  .conditions-title {
    // font-family: "Impact";
    font-weight: 400;
    font-size: 34px;
    line-height: 40px;
    color: #ffffff;
    // padding-left: 100px;
  }
  .conditions-content {
    // padding: 0 100px;
    margin-top: 79px;
    margin-bottom: 120px;
    p {
      // font-family: "Inter";
      font-weight: 300;
      font-size: 22px;
      line-height: 35px;
      color: #d1d1d1;
    }
  }
}
p {
  margin: 0;
  padding: 0;
}
</style>
