<template>
  <div id="home">
    <div class="content">
      <div class="top-container">
        <div class="main-container center-content">
          <div class="content-l">
            <span class="desc_1 desc_after">NFT</span>
            <span class="desc_1">EVERYTHING</span>
            <span class="desc_2">One-Stop NFT Service Provider</span>
            <p @click="goDesign" class="start-btn flex-vh-center">
              Get Started
            </p>
          </div>
          <div class="content-r">
            <div class="content-bg">
              <div class="bg-large">
                <img src="@/assets/images/home/home-bg.png" />
                <!-- <img v-lazy="homeBg" alt="" /> -->
              </div>
              <div class="img_one">
                <img src="@/assets/loading.gif" />
              </div>
            </div>
            <div class="home-dynamic">
              <img src="@/assets/images/home/dynamic-figure.gif" />
              <!-- <img v-lazy="dynamicFigure" alt="" /> -->
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="main-container content-one">
          <div class="title">How to Get A Verified NFT Product ?</div>
          <div class="step-wrap">
            <div>
              <div class="circle-a">
                <img src="../../assets/images/home/vector.png" />
              </div>
              <p class="step-text">Connect</p>
            </div>
            <div class="line" />
            <div>
              <div class="circle-a">
                <img src="@/assets/images/home/vector1.png" />
              </div>
              <p class="step-text">Verify</p>
            </div>
            <div class="line" />
            <div>
              <div class="circle-a">
                <img src="@/assets/images/home/vector2.png" />
              </div>
              <p class="step-text">Design</p>
            </div>
            <div class="line" />
            <div>
              <div class="circle-a">
                <img src="@/assets/images/home/vector3.png" />
              </div>
              <p class="step-text">Check</p>
            </div>
          </div>
        </div>
        <div class="main-container">
          <div class="title">Our Services</div>
          <div class="service-one">
            <div class="service-img">
              <img
                id="img_1"
                class="bg_img"
                src="@/assets/images/home/1-img.png"
              />
            </div>
            <div class="service-right">
              <div class="service-title">NFT Backed Products</div>
              <div class="service-content">
                We have the whole supply-chain on place to help you produce your
                favourite NFT - backed items, totally designed & authorised by
                you!
              </div>
            </div>
          </div>
          <div class="service-one service-bg_2">
            <div class="service-right">
              <div class="service-title">End-to-end NFT solutions</div>
              <div class="service-content service-content-r">
                We have the best talents in designing, programming, and
                economics to help you with a project from scratch, tokenomics
                review, smart-contract deployment, operation and marketing etc.,
                literally anything.
              </div>
            </div>
            <div style="max-width: 730px" class="service-img"></div>
          </div>
          <div class="service-one mb">
            <div class="service-img">
              <img
                id="img_3"
                class="bg_img"
                src="@/assets/images/home/3-img.png"
              />
            </div>
            <div class="service-right">
              <div class="service-title content-mt">
                NFT IP Commercialization
              </div>
              <div class="service-content">
                List and authorize your NFTs on our gallery. We will
                commercialize them across various markets while you are holding.
                Generate cashflows whenever people and merchants use them.
              </div>
            </div>
          </div>
        </div>
        <div style="position: relative" class="home-wrap">
          <div class="bg-inner">
            <div class="main-container img-main">
              <div class="img-list">
                <div
                  class="nft-item"
                  v-for="(item, index) in imageList"
                  :key="index"
                >
                  <img class="nft-img" :src="item.url" />
                  <div class="blank"></div>
                </div>
              </div>
              <div class="center-text">NPLUS</div>

              <div style="max-width: 589px">
                <img src="@/assets/images/home/group-img.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    Footer,
  },
  data() {
    return {
      homeBg: require("@/assets/images/home/home-bg.png"),
      dynamicFigure: require("@/assets/images/home/dynamic-figure.gif"),
      sWidth: window.screen.width,
      sHeight: window.screen.height,
      isLt1920: window.screen.width < 1920,
      showQRcode: false,
      imageList: [
        {
          url: require("@/assets/images/home/nft-1.png"),
        },
        {
          url: require("@/assets/images/home/nft-2.png"),
        },
        {
          url: require("@/assets/images/home/nft-3.png"),
        },
        {
          url: require("@/assets/images/home/nft-4.png"),
        },
        {
          url: require("@/assets/images/home/nft-5.png"),
        },
        {
          url: require("@/assets/images/home/nft-6.png"),
        },
        {
          url: require("@/assets/images/home/nft-7.png"),
        },
        {
          url: require("@/assets/images/home/nft-8.png"),
        },
        {
          url: require("@/assets/images/home/nft-9.png"),
        },
      ],
    };
  },
  computed: {
    hasAccounts() {
      return this.$store.state.user.userInfo.hasAccounts;
    },
  },
  methods: {
    goDesign() {
      this.$router.push("/customize");
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
@import "@/static/css/font.scss";
@import "./index.scss";
</style>
