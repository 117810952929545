<template>
  <div
    v-if="spinning"
    class="loading-mask"
    @touchmove.prevent="onMove"
    :style="styleProps"
  >
    <div class="loading-box">
      <a-spin :indicator="indicator" size="large" :tip="tip" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: String,
      default: "",
    },
    spinning: {
      type: Boolean,
      default: false,
    },
    styleProps: {
      type: Object,
    },
  },
  data() {
    return {
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      tipText:
        "Your payment is on pending, please check your Metamask wallet...  ",
    };
  },
  methods: {
    onMove() {},
  },
};
</script>

<style scoped lang="scss">
.loading-mask {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  .loading-box {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
::v-deep .ant-spin {
  color: #fff;
  .ant-spin-text {
    margin-top: 20px;
    font-family: "Inter";
    font-weight: 400;
    font-size: 22px;
    line-height: 14px;

    letter-spacing: 0.02em;
  }
}
</style>
