<template>
  <div>
    <div class="top-title">
      SPECIAL<img src="@/assets/icon/fire.png" /><span
        >Specialities from the origins</span
      >
    </div>
    <div class="special">
      <left-content-view
        :specialList="specialList"
        :selectedSpecial="selectedSpecial"
        @changeSpecial="changeSpecial"
      ></left-content-view>
      <div class="right-box" v-if="selectedSpecial">
        <div class="img-content">
          <a-carousel arrows>
            <div
              slot="prevArrow"
              slot-scope="props"
              class="custom-slick-arrow"
              style="left: -58px; z-index: 1"
            >
              <img src="@img/special/arrow-prev.png" />
            </div>
            <div
              slot="nextArrow"
              slot-scope="props"
              class="custom-slick-arrow"
              style="right: -50px"
            >
              <img src="@img/special/arrow-next.png" />
            </div>
            <div v-for="(image, key) in selectedSpecial.images" :key="key">
              <img :src="image" class="goods-display" />
            </div>
          </a-carousel>
        </div>

        <div class="goods-title">
          {{ selectedSpecial.name }} &nbsp;# {{ selectedSpecial.sold_count }}/{{
            selectedSpecial.initial_stock
          }}
        </div>

        <div class="detail-title">
          <img class="eth" src="@img/gallery/eth.png" />{{
            selectedSpecial.price
          }}
        </div>

        <div class="operate-box">
          <div
            class="choose-spec"
            v-if="specs.length && selectedSpecial.product_type !== 'case'"
          >
            Size：<span
              v-for="item in specs"
              :key="item"
              :class="{ active: item == currSpec }"
              @click="chooseSpec(item)"
              >{{ item }}</span
            >
          </div>
          <!-- <div class="little-title">Choose your device</div> -->
          <div
            class="choose-spec"
            v-if="selectedSpecial.product_type === 'case'"
          >
            Type：<a-select
              :default-value="currSpec"
              style="
                width: 280px;
                background: rgba(88, 88, 88, 0.4);
                font-size: 17px;
              "
              dropdownClassName="tab-bar-dropdown"
            >
              <a-icon type="caret-down" slot="suffixIcon" />
              <a-select-option
                v-for="item in specs"
                :value="item"
                :key="item"
                >{{ item }}</a-select-option
              >
            </a-select>
          </div>

          <div class="" v-if="address">
            <div class="btn checkout" @click="createOrder">Check Out</div>
            <!-- <div class="btn claim" @click="createOrder">Claim</div> -->
          </div>
          <div class="btn checkout" v-else @click="goConnect">
            Connect Wallet
          </div>
        </div>

        <div class="blank50"></div>

        <div class="detail-title">&nbsp;Details&nbsp;</div>

        <div class="goods-details" v-html="goodsDetails"></div>
      </div>
      <div v-else class="right-box">
        <a-empty description="No Special Data." />
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import LeftContentView from "./components/LeftContentView";
import { walletWeb3modal } from "@u/wallet_web3modal";
export default {
  props: {
    specialList: {
      type: Array,
      default: () => [],
    },
    selectedSpecial: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    address() {
      return this.userInfo?.wallet_address;
    },
    totalAmount() {
      const { stock, sold_count } = this.selectedSpecial;
      return Number(stock) + Number(sold_count);
    },
    goodsDetails() {
      let detailHtml = this.selectedSpecial?.description;
      if (detailHtml && detailHtml.indexOf("&nbsp;") > -1) {
        detailHtml = detailHtml.replace(/&nbsp;/gi, " ");
      }
      return detailHtml;
    },
    specs() {
      if (this.selectedSpecial?.specs) return this.selectedSpecial.specs;
      return [];
    },
    currSpec: {
      get() {
        return this.selectedSpecial.currSpec;
      },
      set() {},
    },
  },
  components: {
    LeftContentView,
  },
  data() {
    return {};
  },
  methods: {
    ...mapMutations({
      saveOrder: "order/SAVE_ORDER",
    }),
    async goConnect() {
      await walletWeb3modal.onConnect();
    },
    changeSpecial(item) {
      this.$emit("changeSpecial", item);
    },
    chooseSpec(spec) {
      this.$emit("changeSpec", spec);
    },
    createOrder() {
      this.saveOrder({
        count: 1,
        special_id: this.selectedSpecial.id,
        title: this.selectedSpecial.category,
        price: this.selectedSpecial.price,
        goodsSize: this.currSpec,
        goodsOption: {
          image: this.selectedSpecial.options[0].image,
          name: "white",
        },
      });
      this.$emit("createOrder");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./special.scss";
</style>
