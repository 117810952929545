<template>
  <div class="left-box">
    <div class="category">
      <div class="category-title">Category</div>
      <div
        class="category-item"
        v-for="item in specialList"
        :key="item.id"
        @click="changeSpecial(item)"
      >
      <!-- {{ item.category }} -->
        <div class="item-cover" v-if="item.id !== selectedSpecial.id"></div>
        <img :src="item.cover_image" />
      </div>
    </div>
    <div class="contactus">
      <div class="content">
        Do you want to present your products here? Contact us!
      </div>
      <div class="email">Email: Service@nplus.zone</div>
      <div class="button" @click="$router.push('/contact')">Contact us >></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    specialList: {
      type: Array,
      default: () => [],
    },
    selectedSpecial: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    changeSpecial(item) {
      this.$emit("changeSpecial", item);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./left.scss";
</style>
