<template>
  <div class="main-container success-wrap">
    <!-- <b class="head-title"
      >&lt;Customize / Customize Your {{ orderList[0].title }} /
      <b style="color: #fff">Check Out</b></b
    > -->
    <div class="content-main flex-center">
      <div class="flex-column-end mr_100">
        <p class="tip-text">Congratulations !</p>
        <p class="desc mt_76">Thank you for choosing us.</p>
        <p class="desc">You have successfully customized your product.</p>
        <p class="desc mt_20">
          Please scan the QR code to track the further information.
        </p>
        <div class="footer-btn">
          <span @click="goBack" class="back-btn flex-vh-center">Back</span>
          <span @click="goDesign" class="more-btn flex-vh-center"
            >Design more</span
          >
        </div>
      </div>
      <div class="qrcode-area">
        <div style="max-widht: 430px">
          <img src="@/assets/images/customize/qrcode-square.png" />
        </div>
        <div class="qrcode-img">
          <vue-qr :size="404" :text="url"></vue-qr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";

export default {
  components: {
    VueQr,
  },
  data() {
    return {
      // url: "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2MDc3MDIwMA==&scene=124#wechat_redirect",
      url: "https://t.me/+hEbiDDF2s-A4MjQ1",
    };
  },
  computed: {
    orderList() {
      let orderData = this.$store.state.order.orderInfo;
      return orderData;
    },
  },
  methods: {
    goBack() {
      this.$router.push("/orders");
    },
    goDesign() {
      this.$emit("backToCustomize");
      // this.$router.push("/customize");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.success-wrap {
  // padding-top: 9px;
  background: radial-gradient(
    39.21% 39.21% at 50% 50%,
    rgba(43, 48, 62, 0.74) 0%,
    #252525 100%
  );
  .head-title {
    // font-family: "Montserrat";
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.02em;

    color: rgba(255, 255, 255, 0.55);
  }
  .title {
    font-family: "Impact";
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;

    letter-spacing: 0.02em;

    color: #ffffff;
  }
  .content-main {
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    font-family: "Montserrat";
    margin-top: 159px;
    .mr_100 {
      margin-right: 100px;
    }
    .tip-text {
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      color: #ffffff;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      margin-top: 20px;
    }
    .desc {
      // width: 460px;
      margin-top: 40px;
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.1em;

      color: #ffffff;

      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .mt_76 {
      margin-top: 76px;
    }
    .footer-btn {
      display: flex;
      margin-top: 80px;
      span {
        // display: block;
        width: 224px;
        height: 40px;
        // text-align: center;
        // line-height: 40px;
        border-radius: 40px;
        // font-family: "Inter";
        font-weight: 700;
        font-size: 18px;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        cursor: pointer;
      }
      .back-btn {
        // border: 1px solid rgba(220, 223, 230, 0.97);
        margin-right: 12px;
        background: #666666;
      }
      .more-btn {
        width: 222px;
        height: 38px;
        // line-height: 38px;
        border: 2px solid rgba(220, 223, 230, 0.97);
      }
    }

    .qrcode-area {
      position: relative;
      .qrcode-img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 409px;
      }
    }
  }
}
</style>
