/**
 * 电子钱包封装
 * web3modal版本
 * Author: Thompson
 */
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from "@s";
import { contract_address, contract_abi, chainId } from "./contract_config";
import dayjs from "dayjs";
import { removeLocalStorage, removeAllStorage } from "@u/storage";

const providerOptions = {
  /* See Provider Options Section */
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: "f5b6a480b1e3401fb87b04cb14af2936", // TODO infuraId
    },
  },
};
const web3Modal = new Web3Modal({
  network: "mainnet", // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

class WalletWeb3modal {
  // 构造函数
  constructor() {
    // this.init();
    console.log(web3Modal);
    this.subscribeProvider();
  }
  // 连接钱包并获取地址以及余额
  async onConnect() {
    const instance = await web3Modal.connect();
    // 获取provider
    this.provider = new ethers.providers.Web3Provider(instance);
    this.signer = this.provider.getSigner();
    this.wallet_address = await this.signer.getAddress();
    const wallet_balence = await this.getUserBalence(this.wallet_address);
    store.dispatch("user/setUserInfo", {
      wallet_address: this.wallet_address,
      wallet_balence,
    });
  }
  // 获取当前钱包地址
  async getWalletAddress() {
    this.wallet_address = await this.signer.getAddress();
    return this.wallet_address;
  }
  // 获取账户余额
  async getUserBalence(wallet_address) {
    const balence = await this.provider.getBalance(wallet_address);
    return ethers.utils.formatEther(balence);
  }
  // 切换到指定网络
  async changeChain() {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId }],
    });
    await this.onConnect();
  }
  // 监听provider变化
  async subscribeProvider() {
    window.ethereum.on("accountsChanged", async (accounts) => {
      // console.log("accountsChanged", accounts, accounts.length);
      if (accounts.length > 0) {
        // 清楚当前用户token
        removeLocalStorage("Access_Token");
        // 重新获取用户信息
        await this.onConnect();
        // 页面重载
        window.location.reload();
      } else {
        this.resetApp();
      }
    });

    window.ethereum.on("chainChanged", async (chainId) => {
      const netId = parseInt(chainId);
      // 重新获取用户信息
      await this.onConnect();
      console.log("netId", netId);
    });

    window.ethereum.on("disconnect", () => {
      console.log("断开连接");
    });
  }
  // 钱包签名
  async sign() {
    if (this.provider) {
      const message = dayjs().valueOf();
      this.signMessage = await this.signer.signMessage(`Login_at:${message}`);
      return {
        message,
        signMessage: this.signMessage,
        wallet_address: this.wallet_address,
      };
    }
  }
  // 连接已有合约
  connectContract() {
    this.contract = new ethers.Contract(
      contract_address,
      contract_abi,
      this.provider
    );
    return this.contract;
  }
  // 创建作为Signer连接的合同对象的新实例
  createSingerContract() {
    this.connectContract();
    this.singerContract = this.contract.connect(this.signer);
    return this.singerContract;
  }
  // 支付订单
  async paySingerContractOrder(orderId, price) {
    let payInfo = null;
    this.createSingerContract();
    try {
      payInfo = await this.singerContract.payOrder(orderId, {
        value: ethers.utils.parseEther(price),
      });
    } catch (error) {
      payInfo = null;
    }
    return payInfo;
  }
  // 验证合约订单
  async checkContractOrder(orderId) {
    let checkOrder = null;
    this.connectContract();
    try {
      checkOrder = await this.contract.chekOrder(orderId);
      checkOrder = ethers.utils.formatEther(checkOrder);
    } catch {
      checkOrder = false;
    }
    return checkOrder;
  }
  // 重置钱包应用
  resetApp() {
    web3Modal.clearCachedProvider();
    store.dispatch("user/setUserInfo", {});
    removeAllStorage();
  }
}

export const walletWeb3modal = new WalletWeb3modal();
